import { useContext } from 'react'

import { useNavigate } from 'react-router-dom'

import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography
} from '@mui/material'
import Link from '@mui/material/Link'

import AuthContext, { AuthContextType } from '@/context/AuthContext'

interface AccountPopoverProps {
  anchorEl: HTMLElement | null
  onClose: () => void
  open: boolean
}

export const AccountPopover = (props: AccountPopoverProps) => {
  const navigate = useNavigate()
  const { anchorEl, onClose, open } = props
  const { user, logout } = useContext(AuthContext) as AuthContextType

  const handleLogout = () => {
    logout()
    navigate('/')
  }

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { width: 200 } } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="overline">Cuenta</Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.username as string}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        <Link
          href={`mailto:booksiq.support@abaqus.cl?subject=Requerimiento%20de%20soporte%20de%20${user?.username}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <MenuItem>
            <Typography variant="body2">Soporte</Typography>
          </MenuItem>
        </Link>
      </MenuList>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        <MenuItem onClick={() => handleLogout()}>Salir</MenuItem>
      </MenuList>
    </Popover>
  )
}
