import { ReactNode } from 'react'

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { darken, lighten, styled } from '@mui/system'

function GroupHeader({ children }: { children: ReactNode }) {
  const theme = useTheme()

  const GroupHeader = styled(Box)({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    zIndex: 100,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8)
  })
  return <GroupHeader>{children}</GroupHeader>
}

export default GroupHeader
