import { ClassifiedType, DatePair } from '@/types/Classified'
import {
  client as axiosClient,
  clientUploadFiles,
  postClientDownloadFile
} from './clients'

const request = axiosClient({
  timeout: 60 * 2 * 1000 // Waiting 2 min
})

/**
 * @deprecated This function is deprecated and will be removed in future releases.
 *
 * Sends a request to create a deprecated classified statement mapping.
 *
 * @param {File[]} files - An array of files to be uploaded.
 * @param {string[]} pages - An array of page identifiers.
 * @param {string} currency - The currency code.
 * @param {string} company - The UUID of the company.
 *
 * @returns {Promise<File>} A promise that resolves with the server response as a file.
 */
export async function statementClassifiedDeprecatedMappingCreateRequest(
  files: File[],
  pages: string[],
  currency: string,
  company: string
) {
  return clientUploadFiles(
    '/api/classified/create/deprecated/',
    files,
    pages,
    {
      currency,
      company_uuid: company
    },
    'blob'
  )
}

/**
 * Sends a request to create a classified statement mapping.
 *
 * @param files - An array of files to be uploaded.
 * @param pages - An array of page identifiers.
 * @param currency - The currency code.
 * @param company - The UUID of the company.
 * @returns A promise that resolves when the request is complete.
 */
export async function statementClassifiedMappingCreateRequest(
  files: File[],
  pages: string[],
  currency: string,
  company: string
) {
  return clientUploadFiles('/api/classified/create/', files, pages, {
    currency,
    company_uuid: company
  })
}

export async function createClassifiedStatementReport(uuid: string) {
  return postClientDownloadFile('/api/classified/report/create/', { uuid })
}

export async function getClassifiedStatementItems(
  classifiedStatementUuid: string
) {
  return request.get(`/api/classified/${classifiedStatementUuid}/items/`)
}

export async function updateClassifiedStatementItems(
  classifiedStatementUuid: string,
  data: Partial<ClassifiedType>[]
) {
  return request.post(
    `/api/classified/${classifiedStatementUuid}/items/update/`,
    data
  )
}

export async function createClassifiedStatementItem(
  classifiedStatementUuid: string,
  data: Partial<ClassifiedType>
) {
  return request.post(
    `/api/classified/${classifiedStatementUuid}/items/create/`,
    data
  )
}

export async function createClassifiedStatementPeriods(
  classifiedStatementUuid: string,
  periods: string[]
) {
  return request.post(
    `/api/classified/${classifiedStatementUuid}/period/create/`,
    {
      periods
    }
  )
}

/**
 * Updates the dates of a classified statement.
 *
 * @param classifiedStatementUuid - The UUID of the classified statement to update.
 * @param data - The data containing the new dates for the classified statement.
 * @returns A promise that resolves with the response of the update request.
 */
export async function updateClassifiedStatementDates(
  classifiedStatementUuid: string,
  data: DatePair[]
) {
  return request.post(
    `/api/classified/${classifiedStatementUuid}/dates/update/`,
    data
  )
}
