import { styled } from '@mui/material/styles'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'

export const Scrollbar = styled(SimpleBar)(({ theme }) => ({
  '.simplebar-scrollbar::before': {
    backgroundColor: '#9DA4AE'
  },

  '.simplebar-track.simplebar-vertical': {
    width: 10,
    backgroundColor: 'transparent'
  },

  '.simplebar-track.simplebar-vertical .simplebar-scrollbar': {
    width: 10,
    borderRadius: 4
  },

  '.simplebar-track.simplebar-vertical .simplebar-scrollbar:hover': {
    backgroundColor: theme.palette.grey[400]
  },

  '.simplebar-track.simplebar-vertical .simplebar-scrollbar:before': {
    backgroundColor: '#9DA4AE'
  }
}))
