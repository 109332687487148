import { useCallback, useMemo } from 'react'
import { FileWithPath, useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

import DeleteIcon from '@mui/icons-material/Delete'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import UploadIcon from '@mui/icons-material/Upload'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  color: '#000',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

interface DropzoneUploadFileProps {
  title?: string
  acceptedFiles: FileWithPath[]
  setAcceptedFiles: (
    files: FileWithPath[] | ((prevFiles: FileWithPath[]) => FileWithPath[])
  ) => void
  acceptedFileTypes?: Record<string, string[]>
  maxFiles?: number
}

export default function DropzoneUploadFile({
  title,
  acceptedFiles,
  setAcceptedFiles,
  acceptedFileTypes,
  maxFiles = 1
}: DropzoneUploadFileProps) {
  const theme = useTheme()

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      if (acceptedFiles.length === 0) {
        toast.warn('Por favor, seleccione un archivo para subir.')
        return
      }

      if (maxFiles === 1) {
        setAcceptedFiles(acceptedFiles)
      } else {
        setAcceptedFiles((prevFiles: FileWithPath[]) => [
          ...prevFiles,
          ...acceptedFiles
        ])
      }
    },
    [maxFiles, setAcceptedFiles]
  )

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: acceptedFileTypes || {
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
      },
      onDrop,
      maxFiles
    })

  const fileExtensions: { [key: string]: string } = {
    'application/vnd.ms-excel': '*.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      '*.xlsx',
    'text/csv': '*.csv',
    'application/zip': '*.zip',
    'application/pdf': '*.pdf'
  }

  const acceptedFilesExtensions: string[] = acceptedFileTypes
    ? Object.keys(acceptedFileTypes).map((file) => fileExtensions[file])
    : ['*.pdf']

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const bytesToMB = (bytes: number): string => {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB'
  }

  const handleRemoveFile = (index: number) => {
    setAcceptedFiles((prevFiles: FileWithPath[]) =>
      prevFiles.filter((_: FileWithPath, i: number) => i !== index)
    )
  }

  return (
    <Box>
      {!!title && (
        <Typography variant="h6">
          <b>{title}</b>
        </Typography>
      )}
      <Box>
        {
          <FormControl
            sx={{
              width: '100%', // Fix IE 11 issue.
              marginTop: theme.spacing(2)
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box sx={{ mt: 2 }}>
                  <div {...getRootProps({ style })}>
                    <input data-testid="file-upload" {...getInputProps()} />
                    <Box display="flex" alignItems="center">
                      <UploadIcon />
                      <Box ml={1}>
                        <Typography>
                          Arrastra el/los archivo aquí, o haz clic para
                          seleccionar el archivo
                        </Typography>
                        <em style={{ color: 'gray' }}>
                          (Sólo archivos{' '}
                          {acceptedFilesExtensions.length > 2
                            ? `${acceptedFilesExtensions
                                .slice(0, -1)
                                .join(', ')} y ${acceptedFilesExtensions.slice(
                                -1
                              )}`
                            : acceptedFilesExtensions.join(' y ')}{' '}
                          son aceptados)
                        </em>
                      </Box>
                    </Box>
                  </div>
                  {acceptedFiles.length > 0 && (
                    <Box>
                      <Grid container mt={2} mb={2}>
                        <Typography variant="h6">Archivos</Typography>
                      </Grid>
                      <Grid container spacing={2}>
                        {acceptedFiles.map((file, index) => {
                          const fileExtension = file.path
                            ?.split('.')
                            .pop()
                            ?.toLowerCase()
                          const isPdf = fileExtension === 'pdf'
                          const isExcel =
                            fileExtension === 'xls' || fileExtension === 'xlsx'
                          return (
                            <Grid item xs={6} key={index}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  padding: theme.spacing(2),
                                  border: '1px solid #ddd',
                                  borderRadius: theme.shape.borderRadius,
                                  boxShadow: theme.shadows[1],
                                  backgroundColor: '#fff'
                                }}
                              >
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  {isPdf && (
                                    <PictureAsPdfIcon
                                      sx={{
                                        marginRight: theme.spacing(1),
                                        fontSize: '3rem' // Increase the size of the icon
                                      }}
                                    />
                                  )}
                                  {isExcel && (
                                    <InsertDriveFileIcon
                                      sx={{
                                        marginRight: theme.spacing(1),
                                        fontSize: '3rem' // Increase the size of the icon
                                      }}
                                    />
                                  )}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  <Typography component="span" display="block">
                                    {file.path}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {bytesToMB(file.size)}
                                  </Typography>
                                </Box>
                                <Button
                                  onClick={() => handleRemoveFile(index)}
                                  sx={{
                                    marginLeft: theme.spacing(1)
                                  }}
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: theme.palette.grey[500],
                                      fontSize: '2rem'
                                    }}
                                  />
                                </Button>
                              </Box>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </FormControl>
        }
      </Box>
    </Box>
  )
}
