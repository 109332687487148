import { ChangeEvent, useEffect, useState } from 'react'

import axios from 'axios'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import useDebounce from '@/hooks/useDebounce'
import {
  companyCreateRequest,
  companyListRequest,
  companyNameGetSIIRequest
} from '@/requests/companies'
import { CompanyType } from '@/types/Company'
import { formatRut, RutFormat, rutValid } from '@/utils'

const filter = createFilterOptions<Company>()

interface Company {
  uuid: string
  rut: string
  name: string
  inputValue?: string
}

interface CompanyAutocompleteProps {
  onCompanySelect: (params: {
    companyUUID: string
    companyName?: string | undefined
    companyRUT: string
  }) => void
}

export default function CompanyAutocomplete({
  onCompanySelect
}: CompanyAutocompleteProps) {
  const [company, setCompany] = useState<Company | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [companyList, setCompanyList] = useState<Company[]>([])
  const [companyLoading, setCompanyLoading] = useState(false)
  const [loading, setLoading] = useState(false) // State to manage loading
  const [isNameFieldDisabled, setIsNameFieldDisabled] = useState(true)
  const [isRutValid, setIsRutValid] = useState<boolean>(false)
  const [loadingNameSuggestion, setLoadingNameSuggestion] = useState(false)
  const [isCompanyNotInit, setIsCompanyNotInit] = useState(false)
  const [newCompany, setNewCompany] = useState<CompanyType>({
    uuid: '',
    rut: '',
    name: ''
  })
  const debouncedNewCompanyRut = useDebounce(newCompany.rut, 850)

  useEffect(() => {
    let isMounted = true
    const fetchCompanyList = async () => {
      setCompanyLoading(true)
      try {
        const response = await companyListRequest()
        if (isMounted) {
          setCompanyList(response.data)
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          toast.error(`Error: ${error.message}`, {
            toastId: 'error-token',
            position: toast.POSITION.BOTTOM_RIGHT
          })
        }
      } finally {
        setCompanyLoading(false)
      }
    }

    fetchCompanyList()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    const getCompanyNameSuggestion = async (rutNewCompany: string) => {
      if (!rutNewCompany || !rutValid(rutNewCompany)) {
        return ''
      }
      setLoadingNameSuggestion(true)
      try {
        const response = await companyNameGetSIIRequest(rutNewCompany)
        if (!isMounted) {
          return ''
        }
        setNewCompany((prevCompany) => ({
          ...prevCompany,
          name: response.data.name
        }))
        if (isEmpty(response.data.name)) {
          setIsCompanyNotInit(true)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoadingNameSuggestion(false)
        setIsNameFieldDisabled(false)
      }
    }
    getCompanyNameSuggestion(debouncedNewCompanyRut)
    return () => {
      isMounted = false
    }
  }, [debouncedNewCompanyRut])

  const handleClose = () => {
    setNewCompany({
      uuid: '',
      name: '',
      rut: ''
    })
    setIsModalOpen(false)
    setIsNameFieldDisabled(true)
    setIsCompanyNotInit(false)
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setLoading(true)
    // Call the endpoint to create a new company
    if (!isEmpty(newCompany.rut) && !isEmpty(newCompany.name)) {
      try {
        const response = await companyCreateRequest({
          uuid: '',
          rut: formatRut(newCompany.rut, RutFormat.DASH),
          name: newCompany.name
        })
        if (response.status === 201) {
          const newCompanyUUID = response.data.uuid
          setCompany({
            uuid: newCompanyUUID,
            name: newCompany.name,
            rut: newCompany.rut
          })
          onCompanySelect({
            companyUUID: newCompanyUUID,
            companyRUT: newCompany.rut,
            companyName: newCompany.name
          })
          setCompanyList([
            ...companyList,
            {
              uuid: newCompanyUUID,
              name: newCompany.name,
              rut: newCompany.rut
            }
          ])
          setNewCompany({
            uuid: '',
            name: '',
            rut: ''
          })
          handleClose()
        }
      } catch (error) {
        console.log(error)
        toast.error('Error al crear la empresa', {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const validateRUT = (rut: string) => {
    const isValid = rutValid(rut)
    setIsRutValid(isValid)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'rut') {
      validateRUT(value)
      const formattedValue = formatRut(value)
      setNewCompany((prevCompany) => ({
        ...prevCompany,
        [name]: formattedValue
      }))
      setIsCompanyNotInit(false)
    } else {
      setNewCompany((prevCompany) => ({ ...prevCompany, [name]: value }))
    }
  }

  return (
    <>
      <Autocomplete
        value={company}
        id="company-autocomplete-with-create"
        filterOptions={(options, params) => {
          // Remove points from the input value
          const cleanedInputValue = params.inputValue.replace(/\./g, '')

          // Filter options using the cleaned input value
          const filtered = filter(options, {
            ...params,
            inputValue: cleanedInputValue
          })

          filtered.unshift({
            inputValue: cleanedInputValue,
            name: `Crear empresa "${cleanedInputValue}"`,
            uuid: '',
            rut: ''
          })

          return filtered
        }}
        loading={companyLoading}
        options={companyList}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option?.inputValue) {
            return option.inputValue
          }

          return `${option.rut} (${option.name})`
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: '11px'
          }
        }}
        renderOption={(props, option) => {
          const isBold = option.name.includes('Crear em')
          return (
            <li {...props}>
              <Typography
                style={{
                  fontWeight: isBold ? 'bold' : 'normal',
                  fontFeatureSettings: "'tnum'",
                  whiteSpace: 'nowrap'
                }}
              >
                {option.rut}
              </Typography>
              &nbsp;
              <Typography style={{ fontWeight: isBold ? 'bold' : 'normal' }}>
                (
                {option.name.length > 57
                  ? option.name.substring(0, 57) + '...'
                  : option.name}
                )
              </Typography>
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Seleccionar empresa"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {companyLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        onChange={(_event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              setIsModalOpen(true)
              validateRUT(newValue)
              setNewCompany({
                rut: formatRut(newValue),
                name: '',
                uuid: ''
              })
            })
          } else if (newValue && newValue.inputValue) {
            setIsModalOpen(true)
            validateRUT(newValue.inputValue)
            setNewCompany({
              rut: formatRut(newValue.inputValue),
              name: '',
              uuid: ''
            })
          } else {
            if (newValue && newValue.uuid) {
              setCompany(newValue)
              onCompanySelect({
                companyUUID: newValue.uuid,
                companyRUT: newValue.rut,
                companyName: newValue.name
              })
            }
            if (newValue && newValue.name.includes('Crear empresa')) {
              setIsModalOpen(true)
              setNewCompany({
                rut: formatRut(newValue.inputValue),
                name: '',
                uuid: ''
              })
            }
          }
        }}
        freeSolo
      />
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '52%',
            left: '52%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '95%' },
              mb: 2
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h5">Agregar una nueva empresa</Typography>
            <TextField
              type="text"
              name="rut"
              error={!isRutValid && newCompany.rut !== ''}
              helperText={
                !isRutValid && newCompany.rut !== '' ? 'RUT inválido' : ''
              }
              value={formatRut(newCompany.rut)}
              onChange={handleInputChange}
              label="RUT"
            />
            {loadingNameSuggestion && (
              <Box>
                <LinearProgress />
                <Typography variant="body2" color="textSecondary">
                  Buscando razón social...
                </Typography>
              </Box>
            )}
            {isCompanyNotInit && (
              <Alert severity="warning">
                RUT no cuenta con actividades vigentes en SII
              </Alert>
            )}
            <TextField
              type="text"
              name="name"
              value={newCompany.name}
              onChange={handleInputChange}
              label="Razón social"
              disabled={isNameFieldDisabled}
            />
            <Alert severity="info">
              Una vez guardada la empresa se intentará buscar automáticamente
              las actividades desde el SII.
            </Alert>
          </Box>
          <Grid container justifyContent="center" sx={{ mt: 2 }} spacing={2}>
            <Grid item>
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!newCompany.name || !newCompany.rut || !isRutValid}
                loading={loading}
              >
                Crear empresa
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}
