import { MouseEvent } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import RedoIcon from '@mui/icons-material/Redo'
import UndoIcon from '@mui/icons-material/Undo'
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography
} from '@mui/material'

import {
  CellChange,
  ChevronCell,
  DropdownCell,
  NumberCell,
  TextCell
} from '@silevis/reactgrid'

interface ToolbarProps {
  handleUndoChanges: () => void
  handleRedoChanges: () => void
  cellChangesIndex: number
  cellChanges: CellChange<
    NumberCell | TextCell | DropdownCell | ChevronCell
  >[][]
  openOptions: boolean
  anchorEl: HTMLElement | null
  handleClickOpenOptions: (event: MouseEvent<HTMLElement>) => void
  handleClickCloseOptions: () => void
  handleOpenColumnEdit: () => void
}

const Toolbar: React.FC<ToolbarProps> = ({
  handleUndoChanges,
  handleRedoChanges,
  cellChangesIndex,
  cellChanges,
  openOptions,
  anchorEl,
  handleClickOpenOptions,
  handleClickCloseOptions,
  handleOpenColumnEdit
}) => (
  <Paper elevation={3} sx={{ padding: 1, borderRadius: '15px 15px 0 0' }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ButtonGroup variant="outlined" aria-label="button group">
          <Tooltip title="Deshacer cambios">
            <span>
              <Button
                onClick={handleUndoChanges}
                disabled={cellChangesIndex === -1}
              >
                <UndoIcon />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Rehacer cambios">
            <span>
              <Button
                onClick={handleRedoChanges}
                disabled={cellChangesIndex === cellChanges.length - 1}
              >
                <RedoIcon />
              </Button>
            </span>
          </Tooltip>
        </ButtonGroup>
        <Typography variant="body1" gutterBottom sx={{ marginLeft: 2 }}>
          Aquí puede realizar acciones como
          <br /> copiar, pegar y cortar datos.
        </Typography>
      </Box>
      <Box>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={openOptions ? 'long-menu' : undefined}
          aria-expanded={openOptions ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClickOpenOptions}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{ 'aria-labelledby': 'long-button' }}
          anchorEl={anchorEl}
          open={openOptions}
          onClose={handleClickCloseOptions}
          slotProps={{
            paper: {
              style: {
                maxHeight: 30 * 4.5,
                width: '30ch',
                transform: 'translateX(-95%)'
              }
            }
          }}
        >
          <MenuItem
            onClick={() => {
              handleOpenColumnEdit()
              handleClickCloseOptions()
            }}
          >
            Editar columnas de periodo
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  </Paper>
)

export default Toolbar
