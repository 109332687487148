import React, { forwardRef, useState } from 'react'

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult
} from '@hello-pangea/dnd'
import dayjs, { Dayjs } from 'dayjs'
import ReactDOM from 'react-dom'
import { toast } from 'react-toastify'

import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import 'dayjs/locale/es'

const DraggablePortal: React.FC<{
  children: React.ReactNode
  provided: DraggableProvided
}> = ({ children, provided }) => {
  return ReactDOM.createPortal(
    <Box
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{ ...provided.draggableProps.style, zIndex: 9999 }}
    >
      {children}
    </Box>,
    document.body
  )
}

interface EditColumnsModalProps {
  open: boolean
  onClose: () => void
  handleSaveNewOrderDates: (dates: string[]) => void
  periods: string[]
}

export const EditColumnsModal = forwardRef<
  HTMLDivElement,
  EditColumnsModalProps
>(({ open, onClose, handleSaveNewOrderDates, periods }, ref) => {
  const [localPeriods, setLocalPeriods] = useState<Dayjs[]>(
    periods.map((p) => dayjs(p))
  )

  const handleAddPeriod = () => {
    const newDate = dayjs()
    setLocalPeriods([...localPeriods, newDate])
  }

  const handleRemovePeriod = (index: number) => {
    const updatedPeriods = localPeriods.filter((_, i) => i !== index)
    const originalPeriod = periods.map((p) => dayjs(p))
    const isOriginalPeriod = originalPeriod.some(
      (period) =>
        period.format('YYYY-MM-DD') === localPeriods[index].format('YYYY-MM-DD')
    )

    if (!isOriginalPeriod) {
      setLocalPeriods(updatedPeriods)
    } else {
      toast.error('No se pueden eliminar los períodos originales.', {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }

  const handleDateChange = (date: Dayjs | null, index: number) => {
    if (date) {
      const isDuplicate = localPeriods.some(
        (period, i) =>
          i !== index &&
          period.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
      )
      if (!isDuplicate) {
        const updatedPeriods = localPeriods.map((p, i) =>
          i === index ? date : p
        )
        setLocalPeriods(updatedPeriods)
      } else {
        toast.error('No se pueden añadir fechas duplicadas.', {
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    }
  }

  const handleSaveChanges = () => {
    const reorderedPeriods = localPeriods.map((p) => p.format('YYYY-MM-DD'))
    // check that there are not duplicates on reorderedPeriods
    const isDuplicate = reorderedPeriods.some(
      (period, i) =>
        reorderedPeriods.indexOf(period) !== i &&
        reorderedPeriods.indexOf(period) !== -1
    )
    if (isDuplicate) {
      toast.error('No se pueden añadir fechas duplicadas.', {
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    } else {
      handleSaveNewOrderDates(reorderedPeriods)
      onClose()
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const reorderedPeriods = Array.from(localPeriods)
    const [removed] = reorderedPeriods.splice(result.source.index, 1)
    reorderedPeriods.splice(result.destination.index, 0, removed)
    setLocalPeriods(reorderedPeriods)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div
        ref={ref}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'white',
          border: '2px solid #000',
          padding: 16
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative'
            }}
          >
            <Typography variant="h6">Editar columnas de períodos</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Puedes añadir, eliminar, modificar y reordenar los periodos que se
            muestran en las columnas del balance.
          </Typography>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="periods">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{ mb: 2 }}
                >
                  {localPeriods.map((period, index) => (
                    <Draggable
                      key={index}
                      draggableId={index.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        const child = (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mb: 2
                            }}
                          >
                            <Box sx={{ mr: 2 }}>
                              <DragIndicatorIcon />
                            </Box>
                            <DatePicker
                              label={`Periodo ${index + 1}`}
                              format="DD/MM/YYYY"
                              value={period}
                              onChange={(date) =>
                                handleDateChange(date ? date : null, index)
                              }
                              disableFuture
                              openTo="day"
                              views={['year', 'month', 'day']}
                              slotProps={{ textField: { size: 'small' } }}
                            />

                            <IconButton
                              disabled
                              onClick={() => handleRemovePeriod(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        )

                        return snapshot.isDragging ? (
                          <DraggablePortal provided={provided}>
                            {child}
                          </DraggablePortal>
                        ) : (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {child}
                          </Box>
                        )
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button
              onClick={handleAddPeriod}
              variant="text"
              startIcon={<AddIcon />}
              sx={{
                textTransform: 'none',

                color: 'primary.main'
              }}
            >
              Añadir período
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button onClick={handleSaveChanges} variant="contained">
              Guardar cambios
            </Button>
            <Button
              color="error"
              onClick={onClose}
              variant="outlined"
              sx={{
                border: 'none',
                '&:hover': { border: 'none', transform: 'none' }
              }}
            >
              Cancelar
            </Button>
          </Box>
        </LocalizationProvider>
      </div>
    </Modal>
  )
})
