import { client as axiosClient } from './clients'

const request = axiosClient({
  timeout: 60 * 2 * 1000 // Waiting 2 min
  // there's an interceptor that adds the token to the request in src/components/RequestInterceptor/index.tsx
})

export async function loginRequest(username: string, password: string) {
  const response = await request.post('/api/auth/login/', {
    username,
    password
  })
  return response
}

export async function resetPasswordRequest(email: string) {
  const response = await request.post('/api/auth/password/reset/', { email })
  return response
}

export async function resetPasswordConfirmRequest(
  uid: string,
  token: string,
  new_password1: string,
  new_password2: string
) {
  const response = await request.post('/api/auth/password/reset/confirm/', {
    uid,
    token,
    new_password1,
    new_password2
  })
  return response
}

export async function refreshAccessTokenRequest(refreshToken: string) {
  const response = await request.post('/api/auth/refresh/', {
    refresh: refreshToken
  })
  return response
}
