import { useCallback, useMemo, useState } from 'react'
import { FileWithPath, useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'

import DeleteIcon from '@mui/icons-material/Delete'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import UploadIcon from '@mui/icons-material/Upload'
import { useTheme } from '@mui/material'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { validatePagesInput } from '@/utils'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  color: '#000',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

interface DropzoneUploadFileProps {
  title?: string
  pageTexts: string[]
  setPageTexts: (files: string[] | ((prevFiles: string[]) => string[])) => void
  acceptedFiles: FileWithPath[]
  setAcceptedFiles: (
    files: FileWithPath[] | ((prevFiles: FileWithPath[]) => FileWithPath[])
  ) => void
  acceptedFileTypes?: Record<string, string[]>
  maxFiles?: number
}

export default function DropzoneUploadFileWithPages({
  title,
  pageTexts,
  setPageTexts,
  acceptedFiles,
  setAcceptedFiles,
  acceptedFileTypes,
  maxFiles = 1
}: DropzoneUploadFileProps) {
  const theme = useTheme()

  const [pageTextErrors, setPageTextErrors] = useState<boolean[]>([])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[]) => {
      if (acceptedFiles.length === 0) {
        toast.warn('Por favor, seleccione un archivo para subir.')
        return
      }

      const pdfFiles = acceptedFiles.filter((file) =>
        file.name.toLowerCase().endsWith('.pdf')
      )
      const xlsxFiles = acceptedFiles.filter((file) =>
        file.name.toLowerCase().endsWith('.xlsx')
      )

      if (
        xlsxFiles.length > 1 ||
        (xlsxFiles.length === 1 && pdfFiles.length > 0)
      ) {
        setErrorMessage(
          'Solo puedes subir un archivo .xlsx o múltiples archivos .pdf, pero no ambos.'
        )
        return
      }

      setErrorMessage(null)
      acceptedFiles.forEach((file: File) => {
        const extension = file.name.split('.').pop()?.toLowerCase()
        const filename = file.name.replace(/\.[^/.]+$/, '')
        Object.defineProperty(file, 'name', {
          writable: true,
          value: `${filename}.${extension}`
        })
      })
      // Clear previous files and states
      setAcceptedFiles([])
      setPageTexts([])
      setPageTextErrors([])

      if (maxFiles === 1) {
        setAcceptedFiles(acceptedFiles)
      } else {
        setAcceptedFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
      }

      setPageTexts(new Array(acceptedFiles.length).fill(''))
      setPageTextErrors(new Array(acceptedFiles.length).fill(false))
    },
    [maxFiles, setAcceptedFiles, setPageTexts]
  )

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: acceptedFileTypes || {
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/pdf': []
      },
      onDrop,
      maxFiles
    })

  const fileExtensions: { [key: string]: string } = {
    'application/vnd.ms-excel': '*.xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      '*.xlsx',
    'text/csv': '*.csv',
    'application/zip': '*.zip',
    'application/pdf': '*.pdf'
  }

  const acceptedFilesExtensions: string[] = acceptedFileTypes
    ? Object.keys(acceptedFileTypes).map((file) => fileExtensions[file])
    : ['*.pdf']

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const bytesToMB = (bytes: number): string => {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB'
  }

  const handlePageTextChange = (index: number, text: string) => {
    const newPageTexts = [...pageTexts]
    newPageTexts[index] = text
    setPageTexts(newPageTexts)

    const newPageTextErrors = [...pageTextErrors]
    newPageTextErrors[index] = !validatePagesInput(text)
    setPageTextErrors(newPageTextErrors)
  }

  const handleRemoveFile = (index: number) => {
    setAcceptedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
    setPageTexts((prevTexts) => prevTexts.filter((_, i) => i !== index))
    setPageTextErrors((prevErrors) => prevErrors.filter((_, i) => i !== index))
  }

  return (
    <Box>
      {!!title && (
        <Typography variant="h6">
          <b>{title}</b>
        </Typography>
      )}
      <Box>
        {
          <FormControl
            sx={{
              width: '100%', // Fix IE 11 issue.
              marginTop: theme.spacing(2)
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box sx={{ mt: 2 }}>
                  <div {...getRootProps({ style })}>
                    <input data-testid="file-upload" {...getInputProps()} />
                    <Box display="flex" alignItems="center">
                      <UploadIcon />
                      <Box ml={1}>
                        <Typography>
                          Arrastra el/los archivo aquí, o haz clic para
                          seleccionar el archivo
                        </Typography>
                        <em style={{ color: 'gray' }}>
                          (Sólo archivos{' '}
                          {acceptedFilesExtensions.length > 2
                            ? `${acceptedFilesExtensions
                                .slice(0, -1)
                                .join(', ')} y ${acceptedFilesExtensions.slice(
                                -1
                              )}`
                            : acceptedFilesExtensions.join(' y ')}{' '}
                          son aceptados)
                        </em>
                      </Box>
                    </Box>
                  </div>
                  {errorMessage && (
                    <Alert severity="error">{errorMessage}</Alert>
                  )}
                  {!errorMessage && acceptedFiles.length > 0 && (
                    <Box>
                      <Grid container mt={2} mb={2}>
                        <Typography variant="h6">Archivos</Typography>
                      </Grid>
                      <Grid container spacing={2}>
                        {acceptedFiles.map((file, index) => {
                          const fileExtension = file.path
                            ?.split('.')
                            .pop()
                            ?.toLowerCase()
                          const isPdf = fileExtension === 'pdf'
                          const isExcel =
                            fileExtension === 'xls' || fileExtension === 'xlsx'
                          return (
                            <Grid container item xs={12} key={index}>
                              <Grid item xs={6}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: theme.spacing(2),
                                    border: '1px solid #ddd',
                                    borderRadius: theme.shape.borderRadius,
                                    boxShadow: theme.shadows[1],
                                    backgroundColor: '#fff'
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {isPdf && (
                                      <PictureAsPdfIcon
                                        sx={{
                                          marginRight: theme.spacing(1),
                                          fontSize: '3rem' // Increase the size of the icon
                                        }}
                                      />
                                    )}
                                    {isExcel && (
                                      <InsertDriveFileIcon
                                        sx={{
                                          marginRight: theme.spacing(1),
                                          fontSize: '3rem' // Increase the size of the icon
                                        }}
                                      />
                                    )}
                                  </Box>
                                  <Box sx={{ flex: 1 }}>
                                    <Typography
                                      component="span"
                                      display="block"
                                    >
                                      {file.path}
                                    </Typography>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {bytesToMB(file.size)}
                                    </Typography>
                                  </Box>

                                  <Button
                                    onClick={() => handleRemoveFile(index)}
                                    sx={{
                                      marginLeft: theme.spacing(1)
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{
                                        color: theme.palette.grey[500],
                                        fontSize: '2rem'
                                      }}
                                    />
                                  </Button>
                                </Box>
                              </Grid>
                              <Grid item xs={5}>
                                {isPdf && (
                                  <TextField
                                    label="Páginas"
                                    type="text"
                                    fullWidth
                                    placeholder="Ejemplo: 1-5, 8, 11-13"
                                    value={pageTexts[index] || ''}
                                    onChange={(e) =>
                                      handlePageTextChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    margin="normal"
                                    error={pageTextErrors[index]}
                                    helperText={
                                      pageTextErrors[index]
                                        ? 'Formato inválido'
                                        : ''
                                    }
                                    sx={{ marginLeft: theme.spacing(2) }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </FormControl>
        }
      </Box>
    </Box>
  )
}
