import { StrictMode, useEffect } from 'react'

import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import App from './App'

import './index.css'

Sentry.init({
  dsn: 'https://43ecb3e82a0dd67b6d085281d0fe2d5b@o1043038.ingest.us.sentry.io/4507091786661888',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false
    }),
    Sentry.feedbackIntegration({
      colorScheme: 'system',
      isNameRequired: true,
      isEmailRequired: true,
      formTitle: 'Reporte de errores',
      nameLabel: 'Nombre',
      namePlaceholder: 'Tu nombre',
      emailLabel: 'Correo electrónico',
      emailPlaceholder: 'tu.correo@example.org',
      messageLabel: 'Descripción',
      messagePlaceholder: '¿Cuál es el error? ¿Qué esperabas?',
      successMessageText: '¡Gracias por tu informe!',
      buttonLabel: '',
      submitButtonLabel: 'Enviar informe de error',
      cancelButtonLabel: 'Cancelar'
    })
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['https://test.elendil.abaqus.cl'],
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development'
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
)
