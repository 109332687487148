import { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, TextField, Typography } from '@mui/material'

import bciLogo from '@/assets/bci_logo.png'
import CenteredBox from '@/components/CenteredBox'
import { resetPasswordRequest } from '@/requests/auth'

const LoginForgotPasswordPage = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [lastRequestTime, setLastRequestTime] = useState(0)

  const handleSendLink = async () => {
    const now = Date.now()
    if (now - lastRequestTime < 60000) {
      // 60000 ms = 1 minute
      toast.error(
        'Por favor, espera un minuto antes de solicitar otro restablecimiento de contraseña'
      )
      return
    }
    setLastRequestTime(now)
    // Simple regex for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!email.trim()) {
      toast.error('Por favor, introduce un correo electrónico')
      return
    }

    if (!emailPattern.test(email)) {
      toast.error('Por favor, introduce un correo electrónico válido')
      return
    }
    // Perform send link logic here
    const response = await resetPasswordRequest(email)
    if (response.status !== 200) {
      toast.error('Error sending link!')
      return
    }
    toast.success('¡Enlace enviado a tu correo electrónico!', {
      autoClose: false
    })
    navigate('/')
  }

  return (
    <CenteredBox>
      <Box
        sx={{
          minWidth: '35%',
          paddingLeft: 4,
          paddingRight: 4
        }}
      >
        <Link to="/">
          <Button variant="text" color="secondary">
            <ArrowBackIcon /> Volver al inicio
          </Button>
        </Link>
      </Box>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault()
          handleSendLink()
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '35%',
          gap: 2, // space between TextField and Button
          backgroundColor: 'white',
          padding: 4, // 4 * theme.spacing unit (usually 8px) = 32px
          borderRadius: 2,
          boxShadow:
            '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)'
        }}
      >
        <img src={bciLogo} alt="bci logo" style={{ maxWidth: '64px' }} />
        <Typography variant="h6" align="left">
          ¿Olvidaste la contraseña?
        </Typography>
        <Typography variant="body2" align="left">
          Ingresa tu mail y te llegará a tu correo un enlace para reestablecer
          tu contraseña
        </Typography>
        <TextField
          label="Correo electrónico"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
          type={'email'}
        />
        <Button variant="contained" color="primary" onClick={handleSendLink}>
          Enviar enlace
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default LoginForgotPasswordPage
