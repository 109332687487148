import { useRef, useState } from 'react'

import { cloneDeep } from 'lodash'
import { toast } from 'react-toastify'

import Box from '@mui/material/Box'

import {
  CellChange,
  CellTemplates,
  ChevronCell,
  Column,
  DefaultCellTypes,
  DropdownCell,
  HeaderCell,
  MenuOption,
  NumberCell,
  ReactGrid,
  Row,
  SelectionMode,
  TextCell
} from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'

import {
  ISelectCell,
  SelectCellTemplate
} from '@/components/SelectCustomTemplate'
import { updateClassifiedStatementItems } from '@/requests/classified'
import { ClassifiedType } from '@/types/Classified'
import { CuentaAnyType } from '@/types/Cuenta'
import { EditColumnsModal } from './EditColumnsModal'
import Toolbar from './Toolbar'
import './grid.css'

type OptionType = {
  value: string
  label: string
  parent: string
}

type Id = number | string

interface GridEditProps {
  tableData: ClassifiedType[]
  setTableData: (data: ClassifiedType[]) => void
  dates: string[]
  handleDateOrderChange: (dates: string[]) => void
  accountClassesOptions: OptionType[]
  accountClassesOptionsMap: Record<string, string>
  uuid: string
  handleOpenRowCreate: () => void
  setRowCreationNewIndex: (index: number) => void
  statementValidated: boolean
}

const COLUMN_WIDTH_WIDE = 300
const ROW_HEIGHT = 33

const isMacOs = () => window.navigator.userAgent.includes('Mac')

/*
  Generates columns for the grid
*/
const getColumns = (dates: string[]): Column[] => {
  const columns = [
    { columnId: 'account', width: COLUMN_WIDTH_WIDE, resizable: true },
    { columnId: 'user_class_code', width: COLUMN_WIDTH_WIDE, resizable: true },
    ...dates.map((date) => ({
      columnId: date,
      width: COLUMN_WIDTH_WIDE,
      resizable: true
    }))
  ]

  return columns
}

/* 
  searches for a chevron cell in given row
*/
const findChevronCell = (row: Row) =>
  row.cells.find((cell) => cell.type === 'chevron') as ChevronCell | undefined

/* 
  searches for a parent of given row
*/
const findParentRow = (rows: Row[], row: Row) =>
  rows.find((r) => {
    const foundChevronCell = findChevronCell(row)
    return foundChevronCell ? r.rowId === foundChevronCell.parentId : false
  })

/* 
  check if the row has children
*/
const hasChildren = (rows: Row[], row: Row): boolean =>
  rows.some((r) => {
    const foundChevronCell = findChevronCell(r)
    return foundChevronCell ? foundChevronCell.parentId === row.rowId : false
  })

/* 
  Checks is row expanded
*/
const isRowFullyExpanded = (rows: Row[], row: Row): boolean => {
  const parentRow = findParentRow(rows, row)
  if (parentRow) {
    const foundChevronCell = findChevronCell(parentRow)
    if (foundChevronCell && !foundChevronCell.isExpanded) return false
    return isRowFullyExpanded(rows, parentRow)
  }
  return true
}

const getExpandedRows = (rows: Row[]): Row[] =>
  rows.filter((row) => {
    const areAllParentsExpanded = isRowFullyExpanded(rows, row)
    return areAllParentsExpanded !== undefined ? areAllParentsExpanded : true
  })

const getDirectChildRows = (rows: Row[], parentRow: Row): Row[] =>
  rows.filter(
    (row) =>
      !!row.cells.find(
        (cell) =>
          cell.type === 'chevron' &&
          (cell as ChevronCell).parentId === parentRow.rowId
      )
  )

const assignIndentAndHasChildren = (
  rows: Row[],
  parentRow: Row,
  indent: number = 0
) => {
  ++indent
  getDirectChildRows(rows, parentRow).forEach((row) => {
    const foundChevronCell = findChevronCell(row)
    const hasRowChildrens = hasChildren(rows, row)
    if (foundChevronCell) {
      foundChevronCell.indent = indent
      foundChevronCell.hasChildren = hasRowChildrens
    }
    if (hasRowChildrens) assignIndentAndHasChildren(rows, row, indent)
  })
}

const buildTree = (rows: Row[]): Row[] =>
  rows.map((row) => {
    const foundChevronCell = findChevronCell(row)
    if (foundChevronCell && !foundChevronCell.parentId) {
      const hasRowChildrens = hasChildren(rows, row)
      foundChevronCell.hasChildren = hasRowChildrens
      if (hasRowChildrens) assignIndentAndHasChildren(rows, row)
    }
    return row
  })

const createHeaderRow = (dates: string[]): Row => ({
  rowId: 'header',
  height: ROW_HEIGHT,
  cells: [
    {
      type: 'header',
      text: 'Cuentas',
      style: {
        background: '#010B1BDE',
        color: '#FFFFFF'
      }
    },
    {
      type: 'header',
      text: 'Cuentas Bci',
      style: {
        background: '#010B1BDE',
        color: '#FFFFFF'
      }
    },
    ...dates.map(
      (date) =>
        ({
          type: 'header',
          text: `${date}`,
          style: {
            background: '#010B1BDE',
            color: '#FFFFFF'
          }
        } as HeaderCell)
    )
  ]
})

const createCells = (
  data: ClassifiedType,
  idx: number,
  parentId: string,
  dates: string[],
  accountClassesOptionsMap: Record<string, string>,
  accountClassesOptions: OptionType[]
) => {
  const baseCells = [
    {
      type: 'chevron',
      nonEditable: true,
      isExpanded: true,
      text: data.account,
      className: idx % 2 === 0 ? 'even-row' : 'odd-row',
      style: {},
      parentId
    },
    {
      type: 'select',
      inputValue: accountClassesOptionsMap[data.user_class_code],
      selectedValue: data.user_class_code,
      values: accountClassesOptions,
      className: idx % 2 === 0 ? 'even-row' : 'odd-row'
    }
  ]

  const dateCells = dates.map((date) => ({
    type: 'number',
    value: data[`${date}_value`] || 0,
    format: Intl.NumberFormat('de', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    }),
    className: idx % 2 === 0 ? 'even-row' : 'odd-row',
    style: {}
  }))

  return [...baseCells, ...dateCells]
}

const generateFoldedRows = (
  tableData: ClassifiedType[],
  dates: string[],
  previousValues?: Row[],
  changes?: CellChange<ChevronCell>[],
  accountClassesOptionsMap: Record<string, string> = {},
  accountClassesOptions: OptionType[] = []
): Row[] => {
  const parentIds = new Set(tableData.map((item) => item.parent))
  const rows: Row[] = []

  const currentChevronCells = previousValues?.filter(
    (row) => row.cells[0].type === 'chevron'
  ) as Row[]

  parentIds.forEach((parentId) => {
    const change = changes?.find((change) => change.rowId === parentId)
    const currentChevronCell = currentChevronCells?.find(
      (row) => row.rowId === parentId
    )
    const isExpanded = change
      ? change.newCell.isExpanded
      : currentChevronCell
      ? (currentChevronCell.cells[0] as ChevronCell).isExpanded
      : true

    rows.push({
      rowId: parentId,
      height: 33,
      reorderable: false,
      cells: [
        {
          type: 'chevron',
          text: parentId,
          isExpanded: isExpanded,
          style: {
            background: '#AAB4CA',
            color: '#010B1BDE'
          },
          hasChildren: true
        } as DefaultCellTypes,
        {
          type: 'text',
          text: '',
          style: {
            background: '#AAB4CA',
            color: '#010B1BDE'
          },
          nonEditable: true
        } as DefaultCellTypes,
        ...(dates.map(() => ({
          type: 'text',
          text: '',
          style: {
            background: '#AAB4CA',
            color: '#010B1BDE'
          },
          nonEditable: true
        })) as DefaultCellTypes[])
      ]
    })

    const indexLookup = new Map(tableData.map((item, index) => [item, index]))

    tableData
      .filter((item) => item.parent === parentId)
      .forEach((data, idx) => {
        const realIndex = indexLookup.get(data)
        rows.push({
          rowId: realIndex as number,
          height: 33,
          cells: createCells(
            data,
            idx,
            parentId,
            dates,
            accountClassesOptionsMap,
            accountClassesOptions
          ) as DefaultCellTypes[]
        })
      })
  })

  return rows
}

const GridFoldedEdit: React.FC<GridEditProps> = ({
  tableData,
  setTableData,
  dates,
  handleDateOrderChange,
  accountClassesOptions,
  uuid,
  handleOpenRowCreate,
  setRowCreationNewIndex,
  accountClassesOptionsMap,
  statementValidated = false
}) => {
  // states
  const reactRef = useRef<ReactGrid>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [columns, setColumns] = useState<Column[]>(getColumns(dates))
  const [openOptions, setOpenOptions] = useState<boolean>(false)
  const [rows, setRows] = useState<Row[]>(
    generateFoldedRows(
      tableData,
      dates,
      undefined,
      undefined,
      accountClassesOptionsMap,
      accountClassesOptions
    )
  )
  const [rowsToRender, setRowsToRender] = useState<Row[]>([
    createHeaderRow(dates),
    ...getExpandedRows(buildTree(rows))
  ])
  const [cellChangesIndex, setCellChangesIndex] = useState<number>(() => -1)
  const [cellChanges, setCellChanges] = useState<
    CellChange<NumberCell | TextCell | DropdownCell | ChevronCell>[][]
  >(() => [])
  const [openModal, setOpenModal] = useState(false)

  const handleClickCloseOptions = () => {
    setOpenOptions(false)
    setAnchorEl(null)
  }

  const handleClickOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
    setOpenOptions(true)
    setAnchorEl(event.currentTarget)
  }

  /**
   * Applies new changes to the data.
   * @param changes - The changes to apply.
   * @param prevData - The previous data.
   * @param usePrevValue - Whether to use the previous value.
   * @returns The updated data.
   */
  const applyNewChangesToData = (
    changes: CellChange[],
    prevData: CuentaAnyType[],
    usePrevValue: boolean = false
  ): ClassifiedType[] => {
    try {
      const dataCopy = cloneDeep(prevData)
      const batchUpdates: Partial<ClassifiedType>[] = []
      // updating frontend
      changes.forEach((change) => {
        const dataIndex = change.rowId as number
        const fieldName = change.columnId as string
        const cell = (usePrevValue ? change.previousCell : change.newCell) as
          | DefaultCellTypes
          | ISelectCell
        let cellValue: string | number = ''
        if (
          (cell.type === 'number' && 'value' in cell) ||
          /^\d{4}-\d{2}-\d{2}$/.test(fieldName)
        ) {
          cellValue = (cell as NumberCell).value || 0
          dataCopy[dataIndex][`${fieldName}_value`] = cellValue
          // create the batch update for the backend
          batchUpdates.push({
            uuid: dataCopy[dataIndex][`${fieldName}_uuid`] as string,
            ['value']: cellValue
          })
        } else if (cell.type === 'select') {
          cellValue = cell.selectedValue as string
          dataCopy[dataIndex][fieldName] = cellValue
          if (fieldName === 'user_class_code') {
            // update the parent if needed from accountClassesOptions
            const parent = accountClassesOptions.find(
              (option) => option.value === cellValue
            )?.parent
            if (parent) {
              dataCopy[dataIndex]['parent'] = parent
            }
            // create the batch update for the backend
            dates.forEach((date) => {
              batchUpdates.push({
                uuid: dataCopy[dataIndex][`${date}_uuid`] as string,
                ['user_class_code']: cellValue as string
              })
            })
          }
        } else {
          console.log('cell type not supported: ', cell.type)
        }
      })
      // updating backend
      if (batchUpdates.length > 0) {
        updateClassifiedStatementItems(uuid, batchUpdates).catch((error) => {
          toast.error(
            `Hubo un problema al actualizar el item. Error: ${
              (error as Error).message
            }`,
            {
              toastId: 'error-token'
            }
          )
        })
      }
      return [...dataCopy] as ClassifiedType[]
    } catch (e) {
      console.error('Error applying changes:', e)
      return prevData as ClassifiedType[]
    }
  }

  const undoChanges = (
    changes: CellChange<TextCell | NumberCell | DropdownCell | ChevronCell>[],
    prevData: ClassifiedType[]
  ): ClassifiedType[] => {
    const newData = applyNewChangesToData(changes, prevData, true)
    setCellChangesIndex((prevIndex) => prevIndex - 1)
    return newData
  }

  const redoChanges = (
    changes: CellChange<TextCell | NumberCell | DropdownCell | ChevronCell>[],
    prevData: ClassifiedType[]
  ): ClassifiedType[] => {
    const newData = applyNewChangesToData(changes, prevData)
    setCellChangesIndex((prevIndex) => prevIndex + 1)
    return newData
  }

  const applyChanges = (
    changes: CellChange<NumberCell | TextCell | DropdownCell | ChevronCell>[],
    prevData: ClassifiedType[]
  ) => {
    const newData = applyNewChangesToData(changes, prevData)
    setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes])
    setCellChangesIndex(cellChangesIndex + 1)
    return newData as ClassifiedType[]
  }

  const handleChanges = (changes: CellChange[]) => {
    if (statementValidated) return
    const isValid = validateChanges(
      changes as CellChange<NumberCell | TextCell | ChevronCell | ISelectCell>[]
    )
    if (!isValid) {
      console.log("Changes aren't valid")
      return
    }
    const excludeChevronChanges = changes.filter(
      (change) => change.type !== 'chevron'
    )
    const newTableData = applyChanges(
      excludeChevronChanges as CellChange<
        NumberCell | TextCell | DropdownCell
      >[],
      tableData
    )
    const chevronChanges = changes.filter(
      (change) => change.type === 'chevron'
    ) as CellChange<ChevronCell>[]
    // Update the state to trigger a re-render
    updateRowsAndRender(newTableData, chevronChanges)
  }

  const updateRowsAndRender = (
    data: ClassifiedType[],
    chevronChanges?: CellChange<ChevronCell>[]
  ) => {
    setTableData(data)
    const updatedRows = generateFoldedRows(
      data,
      dates,
      rows,
      chevronChanges,
      accountClassesOptionsMap,
      accountClassesOptions
    )
    setRows(updatedRows)
    setRowsToRender([
      createHeaderRow(dates),
      ...getExpandedRows(buildTree(updatedRows))
    ])
  }

  const validateChanges = (
    changes: CellChange<NumberCell | TextCell | ChevronCell | ISelectCell>[]
  ) => {
    for (const change of changes) {
      if (change.columnId === 'user_class_code') {
        if (
          !accountClassesOptionsMap[
            (change.newCell as ISelectCell).selectedValue as string
          ]
        ) {
          toast.error(
            `La clase de cuenta "${
              (change.newCell as ISelectCell).selectedValue
            }" no es válida. Por favor, seleccione una clase de cuenta válida.`,
            {
              toastId: 'error-token'
            }
          )
          return false
        }
      }
    }
    return true
  }

  const handleUndoChanges = () => {
    if (cellChangesIndex >= 0) {
      const undoData = undoChanges(cellChanges[cellChangesIndex], tableData)
      updateRowsAndRender(undoData)
    }
  }

  const handleRedoChanges = () => {
    if (cellChangesIndex + 1 <= cellChanges.length - 1) {
      const redoData = redoChanges(cellChanges[cellChangesIndex + 1], tableData)
      updateRowsAndRender(redoData)
    }
  }

  const handleColumnResize = (ci: Id, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci)
      const resizedColumn = prevColumns[columnIndex]
      const updatedColumn = { ...resizedColumn, width }
      prevColumns[columnIndex] = updatedColumn
      return [...prevColumns]
    })
  }

  const handleKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((!isMacOs() && e.ctrlKey) || e.metaKey) {
      switch (e.key) {
        case 'z':
          if (e.shiftKey) {
            handleRedoChanges()
          } else {
            handleUndoChanges()
          }
          return
        case 'y':
          if (!isMacOs()) {
            handleRedoChanges()
          }
          return
      }
    }
  }

  const CustomCellTemplates: CellTemplates = {
    select: new SelectCellTemplate()
  }

  const handleContextMenu = (
    _selectedRowIds: Id[],
    _selectedColIds: Id[],
    _selectionMode: SelectionMode,
    menuOptions: MenuOption[]
  ): MenuOption[] => {
    menuOptions = [
      ...menuOptions,
      {
        id: 'addRow',
        label: 'Agregar fila debajo',
        handler: () => {
          const focusedCell = reactRef.current?.state.focusedLocation
          const newIndex = (focusedCell?.row.rowId as number) + 1
          handleOpenRowCreate()
          setRowCreationNewIndex(newIndex)
        }
      }
    ]

    return menuOptions
  }

  const handleOpenColumnEdit = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSaveNewOrderDates = (newOrderDates: string[]) => {
    if (newOrderDates !== dates && newOrderDates)
      handleDateOrderChange(newOrderDates)
  }

  return (
    <Box sx={{ margin: '0 auto' }}>
      {!statementValidated && (
        <Toolbar
          handleUndoChanges={handleUndoChanges}
          handleRedoChanges={handleRedoChanges}
          cellChangesIndex={cellChangesIndex}
          cellChanges={cellChanges}
          openOptions={openOptions}
          anchorEl={anchorEl}
          handleClickOpenOptions={handleClickOpenOptions}
          handleClickCloseOptions={handleClickCloseOptions}
          handleOpenColumnEdit={handleOpenColumnEdit}
        />
      )}
      <Box
        sx={{
          maxWidth: {
            xs: '320px', // 100% for extra-small screens
            sm: '640px', // 90% for small screens
            md: '900px', // 80% for medium screens
            lg: '1400px', // 70% for large screens
            xl: '1600px' // 60% for extra-large screens
          },
          maxHeight: {
            xs: '60vh', // 60vh for extra-small screens
            md: '70vh' // 70vh for medium and larger screens
          },
          overflow: 'scroll'
        }}
        onKeyDown={handleKeyPressed}
      >
        <ReactGrid
          ref={reactRef}
          columns={columns}
          rows={rowsToRender}
          enableRangeSelection
          enableFillHandle
          // known issue multi change at once: https://github.com/silevis/reactgrid/pull/292
          onCellsChanged={handleChanges}
          onColumnResized={handleColumnResize}
          customCellTemplates={CustomCellTemplates}
          onContextMenu={handleContextMenu}
          stickyTopRows={1}
          labels={{
            legacyBrowserHeader: 'Por favor, actualiza a un navegador moderno.',
            legacyBrowserText:
              'Tu navegador actual no puede ejecutar nuestro contenido, por favor asegúrate de que tu navegador está completamente actualizado o prueba con un navegador diferente. Recomendamos encarecidamente el uso de la versión más reciente de Google Chrome, Microsoft Edge, Firefox, Safari y Opera.',
            copyLabel: 'Copiar',
            cutLabel: 'Cortar',
            pasteLabel: 'Pegar',
            appleMobileDeviceContextMenuPasteAlert:
              'Usa ⌘ + c para copiar, ⌘ + x para cortar y ⌘ + v para pegar.',
            otherBrowsersContextMenuPasteAlert:
              'Usa ctrl + c para copiar, ctrl + x para cortar y ctrl + v para pegar.',
            actionNotSupported:
              'Esta acción no es compatible con este navegador.'
          }}
        />
      </Box>
      <EditColumnsModal
        open={openModal}
        onClose={handleCloseModal}
        handleSaveNewOrderDates={handleSaveNewOrderDates}
        periods={dates}
      />
    </Box>
  )
}

export default GridFoldedEdit
