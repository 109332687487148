import { useState } from 'react'
import { Link } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, Tab, Tabs, Typography } from '@mui/material'

import Classified from '@/pages/AccountClassified'
import AccountIFRSClasification from '@/pages/AccountIFRSClasification'
import AccountTributarioClasification from '@/pages/AccountTributarioClasification'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  sx?: object
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

const UploadTabs = () => {
  const [value, setValue] = useState(0)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Link to="/statements">
        <Button variant="text" sx={{ color: '#010B1B99', px: 0 }}>
          <ArrowBackIcon /> Volver a balances
        </Button>
      </Link>
      <Typography variant="h4" gutterBottom sx={{ fontSize: '24px' }}>
        Crear balance nuevo
      </Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="upload tabs"
        sx={{ borderBottom: 1, borderColor: '#21212114' }}
      >
        <Tab label="Tributario" {...a11yProps(0)} sx={{ fontWeight: 'bold' }} />
        <Tab label="IFRS" {...a11yProps(1)} sx={{ fontWeight: 'bold' }} />
        <Tab
          label="Clasificados"
          {...a11yProps(2)}
          sx={{ fontWeight: 'bold' }}
        />
      </Tabs>
      <TabPanel value={value} index={0} sx={{ padding: 0 }}>
        <AccountTributarioClasification />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AccountIFRSClasification />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Classified />
      </TabPanel>
    </Box>
  )
}

export default UploadTabs
