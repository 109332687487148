import axios from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { FileWithPath } from 'react-dropzone'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import CheckIcon from '@mui/icons-material/Check'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import CompanyAutocomplete from '@/components/CompanyAutocomplete'
import DropzoneUploadFile from '@/components/DropzoneUploadFile'
import ProcessingModal from '@/components/ProcessingModal'
import { statementIFRSMappingCreateRequest } from '@/requests/ifrs'
import { validatePagesInput } from '@/utils'

import 'dayjs/locale/es'

const AccountClassification = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [company, setCompanyUUID] = useState('')
  const [currency, setCurrency] = useState('')
  const [companyType, setCompanyType] = useState('')
  const [companyRUT, setCompanyRUT] = useState('')
  const [pages, setPages] = useState('')
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPath[]>([])
  const [isValidPages, setIsValidPages] = useState(true)
  const [datePeriodCurrent, setDatePeriodCurrent] = useState<Dayjs | null>(null)
  const [datePeriodPrevious, setDatePeriodPrevious] = useState<Dayjs | null>(
    null
  )
  const [submitting, setSubmitting] = useState(false)

  const clearAcceptedFiles = () => {
    setAcceptedFiles([])
  }

  async function handleSubmit() {
    try {
      await handleFileUpload(acceptedFiles)
      clearAcceptedFiles()
    } catch (error) {
      console.error('Failed to upload file:', error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleFileUpload = async (file: File[]) => {
    // eslint-disable-next-line no-debugger
    if (!company || !currency) {
      toast.error(
        'Por favor, selecciona la empresa, la moneda y tipo de empresa',
        {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        }
      )
      throw new Error(
        'Por favor, selecciona la empresa, la moneda y tipo de empresa'
      )
    }

    if (!datePeriodCurrent || !datePeriodPrevious) {
      toast.error(
        'Por favor, selecciona las fechas de los periodos actual y anterior',
        {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        }
      )
      throw new Error(
        'Por favor, selecciona las fechas de los periodos actual y anterior'
      )
    }

    try {
      const response = await statementIFRSMappingCreateRequest(
        file[0],
        currency,
        company,
        companyType,
        pages,
        datePeriodCurrent.format('DD-MM-YYYY'),
        datePeriodPrevious.format('DD-MM-YYYY')
      )
      // Check if the response has an error status
      if (response.status !== 200) {
        toast.error(
          `Hubo un problema al descargar el reporte. Estado: ${response.status}`,
          {
            toastId: 'error-token',
            position: toast.POSITION.BOTTOM_RIGHT
          }
        )
        return
      }

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${companyRUT}.xlsx`)
      document.body.appendChild(link)
      link.click()

      // Clear the blob reference
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Display a toast notification with the error message
        toast.error(`Error: ${error.message}`, {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        })
        throw new Error(error.message)
      }
    }
  }

  const handleChangePages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setPages(value)
    setIsValidPages(validatePagesInput(value))
  }

  const handleCompanySelect = ({
    companyUUID,
    companyRUT
  }: {
    companyUUID: string
    companyRUT: string
    companyName?: string
  }) => {
    if (!companyUUID) {
      return
    }
    setCompanyUUID(companyUUID)
    setCompanyRUT(companyRUT)
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Paper
        elevation={3}
        variant="elevation"
        style={{ padding: '16px', marginBottom: '16px' }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">Información</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CompanyAutocomplete onCompanySelect={handleCompanySelect} />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">Seleccionar moneda</MenuItem>
                    <MenuItem value="CLP">Pesos Chilenos</MenuItem>
                    <MenuItem value="MCLP">Miles de Pesos Chilenos</MenuItem>
                    <MenuItem value="USD">Dólares Estadounidenses</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    value={companyType}
                    onChange={(e) => setCompanyType(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value="">Seleccionar tipo de empresa</MenuItem>
                    <MenuItem value="S">Aseguradora</MenuItem>
                    <MenuItem value="B">Corredora</MenuItem>
                    <MenuItem value="M">Mutuaria</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Fecha periodo actual"
                    value={datePeriodCurrent}
                    onChange={(newValue) => setDatePeriodCurrent(newValue)}
                    slotProps={{ textField: { fullWidth: true } }}
                    maxDate={dayjs()}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Fecha periodo anterior"
                    value={datePeriodPrevious}
                    onChange={(newValue) => setDatePeriodPrevious(newValue)}
                    slotProps={{ textField: { fullWidth: true } }}
                    maxDate={dayjs()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Páginas"
                    type="text"
                    fullWidth
                    placeholder="Ejemplo: 1-5, 8, 11-13"
                    onChange={handleChangePages}
                    error={!isValidPages}
                    helperText={
                      !isValidPages
                        ? 'El formato de las páginas no es válido. Ejemplo: 1-5, 8, 11-13'
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Paper>
      <Paper elevation={3} variant="elevation" style={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Subir archivo</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <DropzoneUploadFile
              setAcceptedFiles={setAcceptedFiles}
              acceptedFiles={acceptedFiles}
              acceptedFileTypes={{
                'application/pdf': ['.pdf'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  []
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{
            margin: theme.spacing(1, 1, 1, 0),
            color: theme.palette.grey[700],
            borderColor: theme.palette.grey[700]
          }}
          type="button"
          variant="outlined"
          onClick={() => {
            clearAcceptedFiles()
            navigate('/statements')
          }}
        >
          Cancelar
        </Button>
        <Button
          sx={{
            margin: theme.spacing(1, 1, 1, 0)
          }}
          onClick={() => {
            setSubmitting(true)
            handleSubmit()
          }}
          variant="contained"
          color="primary"
          disabled={acceptedFiles.length === 0}
          startIcon={<CheckIcon />}
        >
          Crear reporte
        </Button>
      </Box>
      <ProcessingModal open={submitting} />
    </Box>
  )
}

export default AccountClassification
