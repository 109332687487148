import { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Box, Button, TextField, Typography } from '@mui/material'

import bciLogo from '@/assets/bci_logo.png'
import CenteredBox from '@/components/CenteredBox'
import AuthContext, { AuthContextType } from '@/context/AuthContext'

const LoginPage = () => {
  const { login, isAuthenticated } = useContext(AuthContext) as AuthContextType
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/statements')
    }
  }, [isAuthenticated, navigate])

  const handleLogin = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (!username.trim()) {
      toast.error('Por favor, introduce un nombre de usuario')
      return
    }

    if (!password.trim()) {
      toast.error('Por favor, introduce una contraseña')
      return
    }

    const success = await login(username, password)
    if (success) {
      // wait for state to be updated
      setTimeout(() => {
        navigate('/statements')
      }, 5)
    }
  }
  return (
    <CenteredBox>
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '35%',
          gap: 2, // space between TextField and Button
          backgroundColor: 'white',
          padding: 4, // 4 * theme.spacing unit (usually 8px) = 32px
          borderRadius: 2,
          boxShadow:
            '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)'
        }}
      >
        <img src={bciLogo} alt="bci logo" style={{ maxWidth: '64px' }} />
        <Typography variant="h6" align="left">
          Iniciar sesión
        </Typography>
        <Typography variant="body2" align="left">
          Ingrese tus datos para acceder al sistema
        </Typography>
        <TextField
          label="Nombre de usuario"
          autoComplete="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Contraseña"
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" type="submit">
          Entrar
        </Button>
        <Button
          variant="text"
          color="secondary"
          onClick={() => navigate('/forgot-password')}
        >
          ¿Olvidaste tu contraseña?
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default LoginPage
