import {
  CellStyle,
  DefaultCellTypes,
  NumberCell,
  TextCell
} from '@silevis/reactgrid'

export const emptyTextCell: TextCell = { type: 'text', text: '' }

// forked from: https://codesandbox.io/p/sandbox/reactgrid-liquidity-planner-526ps
// example from main code

const numberFormat = new Intl.NumberFormat('de', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})

export const textCell = (
  text: string,
  className = '',
  style?: CellStyle
): TextCell => ({ type: 'text', text, className, style })

export const numberCell = (
  value: number,
  className = '',
  style?: CellStyle
): NumberCell => ({
  type: 'number',
  value,
  className,
  style,
  format: numberFormat
})

export const numberVerifyCell = (
  value: number,
  className = '',
  style?: CellStyle
): NumberCell => ({
  type: 'number',
  value,
  className,
  style: {
    ...style,
    color: value === 0 ? '#95DE64' : '#FF7875'
  },
  format: numberFormat
})

export const nonEditable = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  nonEditable: true
})

export const showZero = (cell: NumberCell): NumberCell => ({
  ...cell,
  nanToZero: true,
  hideZero: false
})

export const bottomLine = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      bottom: {
        width: '1px',
        color: '#A6A6A6',
        style: 'solid'
      }
    }
  }
})

export const noSideBorders = (cell: DefaultCellTypes): DefaultCellTypes => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      left: {
        style: 'none'
      },
      right: {
        style: 'none'
      }
    }
  }
})
