// This component is hidden from the sidebar menu
// and is not used in the app. It's under development.
// It's a component that allows the user to edit a table classified

import React, { FormEvent, useCallback, useEffect, useState } from 'react'

import { isAxiosError } from 'axios'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckIcon from '@mui/icons-material/Check'
import DownloadIcon from '@mui/icons-material/Download'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  MenuList,
  TextField,
  Typography
} from '@mui/material'
import Autocomplete, { AutocompleteValue } from '@mui/material/Autocomplete'
import GroupItems from '@mui/material/List'
import { useTheme } from '@mui/material/styles'

import NumericField from '@/components/DialogNumericTextField'
import GridFoldedEdit from '@/components/GridFoldedEdit'
import GroupHeader from '@/components/GroupHeader'
import {
  statementAccountClassesListRequest,
  statementMappingGetRequest,
  statementMappingUpdateRequest
} from '@/requests/accounts'
import {
  createClassifiedStatementItem,
  createClassifiedStatementPeriods,
  createClassifiedStatementReport,
  getClassifiedStatementItems,
  updateClassifiedStatementDates
} from '@/requests/classified'
import { neutral } from '@/theme/colors'
import { ClassifiedType } from '@/types/Classified'

import FinalizeModal from './FinalizeModal'

import 'dayjs/locale/es'

type OptionType = {
  value: string
  label: string
  parent: string
}

type MergedItem = {
  account: string
  [key: string]: string | number
}

type CompanyData = {
  name?: string
  rut?: string
}

const mergePeriods = (data: Record<string, ClassifiedType[]>): MergedItem[] => {
  const periods = Object.keys(data)
  const allAccounts = new Set<string>()

  // Collect all unique accounts
  periods.forEach((period) => {
    data[period].forEach((item) => {
      allAccounts.add(item.account)
    })
  })
  // Create the merged list
  const mergedList = Array.from(allAccounts).map((account) => {
    const mergedItem: MergedItem = { account }

    periods.forEach((period) => {
      const periodData = data[period].find((item) => item.account === account)
      mergedItem[`${period}_uuid`] = periodData ? periodData.uuid : ''
      mergedItem[`${period}_value`] = periodData ? periodData.value : 0
      mergedItem['user_class_code'] = periodData
        ? periodData.user_class_code
        : ''
      mergedItem['parent'] = periodData ? periodData.parent : ''
      mergedItem['account'] = periodData ? periodData.account : ''
      mergedItem['cleaned_account'] = periodData ? periodData.account : ''
    })

    return mergedItem
  })

  return mergedList
}

const BalanceDetailsClassified: React.FC = () => {
  const theme = useTheme()
  const { uuid } = useParams<{ uuid: string }>()

  const [openFinalizeModal, setOpenFinalizeModal] = useState(false)
  const [tableData, setTableData] = useState<ClassifiedType[]>([])
  const [dates, setDates] = useState<string[]>([])
  const [companyData, setCompanyData] = useState<CompanyData>({})
  const [confidence, setConfidence] = useState('')
  const [confidenceColor, setConfidenceColor] = useState('')
  const [statementValidated, setStatementValidated] = useState(false)
  const [accountClassesOptions, setAccountClassesOptions] = useState<
    OptionType[]
  >([])
  const [openRowCreate, setOpenRowCreate] = useState(false)
  const [rowCreationNewIndex, setRowCreationNewIndex] = useState<number>(0)
  const [rowCreationUserClassCode, setRowCreationUserClassCode] =
    useState<string>('001')
  const [forceRenderKey, setForceRenderKey] = useState(0)

  const accountClassesOptionsMap = accountClassesOptions.reduce(
    (acc: { [key: string]: string }, option: OptionType) => {
      acc[option.value] = option.label
      return acc
    },
    {}
  )

  useEffect(() => {
    const fecthStatementAssetClasses = async () => {
      try {
        const response = await statementAccountClassesListRequest()
        // convert response.data to accountClassesOptions
        const accountClassesOptions = response?.data?.map(
          (item: { code: string; subcategory: string; category: string }) => {
            return {
              value: item.code,
              label: item.subcategory,
              parent: item.category
            }
          }
        )
        setAccountClassesOptions(accountClassesOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fecthStatementAssetClasses()
  }, [])

  function determineConfidenceLevel(cellsConfidence: number) {
    if (cellsConfidence < 65) {
      setConfidenceColor('#D44C47') // Red Color
      return 'BAJA'
    } else if (cellsConfidence >= 65 && cellsConfidence < 85) {
      setConfidenceColor('#CB912F') // Yellow Color
      return 'MEDIA'
    } else {
      setConfidenceColor('#448361') // Green Color
      return 'ALTA'
    }
  }

  const fetchStatementMapping = useCallback(async () => {
    const responseStatement = await statementMappingGetRequest(uuid as string)
    setConfidence(
      determineConfidenceLevel(responseStatement?.data?.cells_confidence)
    )
    setCompanyData((currentData) => ({
      ...currentData,
      rut: responseStatement?.data?.company_rut,
      name: responseStatement?.data?.company_name
    }))
    setStatementValidated(responseStatement?.data?.status === 'AP')

    if (accountClassesOptions.length > 0) {
      const responseStatementItems = await getClassifiedStatementItems(
        uuid as string
      )

      // Transform
      responseStatementItems.data.data.forEach((item: ClassifiedType) => {
        const parent = accountClassesOptions.find(
          (option) => option.value === item.user_class_code
        )?.parent

        item.parent = parent as string
      })
      const groupedData = responseStatementItems.data.data.reduce(
        (acc: Record<string, ClassifiedType[]>, item: ClassifiedType) => {
          const date = item.date // Assuming each item has a 'date' property
          if (!acc[date]) {
            acc[date] = []
          }
          acc[date].push(item)
          return acc
        },
        {}
      )
      const mergedData = mergePeriods(groupedData)

      // Set data
      setDates(responseStatementItems.data.dates)
      setTableData(mergedData as ClassifiedType[])
    }
  }, [uuid, accountClassesOptions])

  useEffect(() => {
    fetchStatementMapping()
  }, [fetchStatementMapping])

  const forceRefreshDataTable = async () => {
    await fetchStatementMapping()
    setForceRenderKey((prevKey) => prevKey + 1)
  }

  const handleOpenRowCreate = () => {
    setOpenRowCreate(true)
  }
  const handleCloseRowCreate = () => {
    setOpenRowCreate(false)
  }

  /**
   * Handles the change in the order of dates.
   *
   * This function performs the following tasks:
   * 1. Identifies dates that are in the new order but not in the current dates.
   * 2. Creates a new row for each of these new dates with default values.
   * 3. Reorders the dates and updates the backend with the new order.
   * 4. Forces a refresh of the data table.
   *
   * @param {string[]} newOrder - The new order of dates.
   */
  const handleDateOrderChange = async (newOrder: string[]) => {
    // We need to create a empty row for each date that is in newOrder but not in dates
    if (newOrder.length !== dates.length) {
      // dates that are in newOrder but not in dates
      const periods = newOrder.filter((date) => !dates.includes(date))
      await createClassifiedStatementPeriods(uuid as string, periods)
    }

    // doing the reorder of dates
    const datesChange = dates.map((oldDate, index) => ({
      old_date: oldDate,
      new_date: newOrder[index]
    }))
    if (uuid) {
      await updateClassifiedStatementDates(uuid as string, datesChange)
      toast.success('Periodos actualizados correctamente.', {
        toastId: 'success-token'
      })
      forceRefreshDataTable()
    }
  }

  const handleOpenFinalizeModal = () => {
    setOpenFinalizeModal(true)
  }

  const handleCloseFinalizeModal = () => {
    setOpenFinalizeModal(false)
  }

  const handleFinalizeSubmit = () => {
    if (uuid) {
      sendValidationReport(uuid, 'AP')
      handleCloseFinalizeModal()
    }
  }

  const handleBackToEdit = () => {
    if (uuid) {
      sendValidationReport(uuid, 'PA')
    }
  }

  const handleRowSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (!uuid) return
    event.preventDefault()
    handleCloseRowCreate()
    setOpenRowCreate(false)
    const formData = new FormData(event.currentTarget)
    const newRows: Partial<ClassifiedType>[] = []
    for (let i = 0; i < dates.length; i++) {
      const value = formData.get(`value_${dates[i]}`) as string
      const newRow: Partial<ClassifiedType> = {
        date: dates[i],
        index: rowCreationNewIndex,
        account: formData.get('account') as string,
        user_class_code: rowCreationUserClassCode,
        period: dates[i],
        value: parseFloat(value)
      }
      newRows.push(newRow)
    }
    // Send newRow to your API endpoint
    try {
      const responses = await Promise.all(
        newRows.map((newRow) =>
          createClassifiedStatementItem(uuid, newRow as Partial<ClassifiedType>)
        )
      )

      const allSuccessful = responses.every(
        (response) => response.status === 201
      )

      if (allSuccessful) {
        toast.success('Fila creada correctamente.', {
          toastId: 'success-token'
        })
        forceRefreshDataTable()
      } else {
        toast.error('Hubo un problema al crear algunas filas.', {
          toastId: 'error-token'
        })
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(
          `Hubo un problema al crear el item. Error: ${
            error.response?.data?.message as string
          }`,
          {
            toastId: 'error-token'
          }
        )
        const fields = error.response?.data?.extra.fields || {}

        for (const [field, messages] of Object.entries(fields)) {
          for (const message of messages as string) {
            toast.error(`Campo: ${field} - ${message}`)
          }
        }
      }
      console.error(error)
    }
  }

  /**
   * Sends a validation report for a classified statement.
   *
   * @param uuid - The unique identifier of the statement to validate.
   * @param status - The status of the statement, which can be either 'AP' (Approved) or 'PA' (Pending Approval).
   * @returns A promise that resolves when the validation process is complete.
   *
   * @throws Will display a toast error message if there is a problem with the validation process.
   */
  const sendValidationReport = async (uuid: string, status: string) => {
    try {
      const response = await statementMappingUpdateRequest(uuid, status)

      // Check if the response has an error status
      if (response.status !== 201) {
        toast.error(
          `Hubo un problema al validar el balance. Estado: ${response.status}`,
          {
            toastId: 'error-token',
            position: toast.POSITION.BOTTOM_RIGHT
          }
        )
        return
      }
      setStatementValidated(response.data.status === 'AP')
    } catch (error) {
      toast.error(
        `Hubo un error validando el balance: ${(error as Error).message}`,
        {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        }
      )
    }
  }

  const handleDownloadReport = async () => {
    try {
      const response = await createClassifiedStatementReport(uuid as string)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${companyData.rut}.xlsx`)
      document.body.appendChild(link)
      link.click()

      // Clear the blob reference
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      toast.error(
        `Hubo un problema al descargar el reporte. Error: ${
          (error as Error).message
        }`,
        {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        }
      )
    }
  }

  return (
    <Grid container>
      <Grid item>
        <Link to="/statements">
          <Button variant="text" sx={{ color: '#010B1B99', px: 0 }}>
            <ArrowBackIcon /> Volver a balances
          </Button>
        </Link>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        sx={{ mb: 2, mt: 2 }}
      >
        <Grid item>
          <Grid>
            <Typography variant="h5" sx={{ mr: 2, whiteSpace: 'nowrap' }}>
              {companyData.rut}
            </Typography>
          </Grid>
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid item sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <AccountCircleOutlinedIcon sx={{ color: 'gray', mr: 1 }} />
              <Typography
                variant="h6"
                fontWeight={400}
                fontSize="1rem"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {companyData.name}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      bgcolor: confidenceColor,
                      display: 'inline-block',
                      mr: 1
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    Confianza OCR {confidence.toLowerCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {statementValidated ? (
            <>
              <Button
                variant="outlined"
                onClick={() => handleBackToEdit()}
                startIcon={<EditOutlinedIcon />}
                sx={{
                  mr: 2,
                  margin: theme.spacing(1, 1, 1, 0),
                  color: theme.palette.grey[700],
                  borderColor: theme.palette.grey[700]
                }}
              >
                Volver a editar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleDownloadReport()}
                startIcon={<DownloadIcon />}
              >
                Descargar
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenFinalizeModal()}
              startIcon={<CheckIcon />}
            >
              Finalizar edición
            </Button>
          )}
        </Grid>
      </Grid>
      {tableData.length === 0 && <LinearProgress />}
      {tableData.length > 0 &&
        dates.length > 0 &&
        accountClassesOptions.length > 0 && (
          <GridFoldedEdit
            key={`${uuid}-${forceRenderKey}`}
            uuid={uuid as string}
            tableData={tableData}
            setTableData={setTableData}
            dates={dates}
            handleDateOrderChange={handleDateOrderChange}
            handleOpenRowCreate={handleOpenRowCreate}
            setRowCreationNewIndex={setRowCreationNewIndex}
            accountClassesOptions={accountClassesOptions}
            accountClassesOptionsMap={accountClassesOptionsMap}
            statementValidated={statementValidated}
          />
        )}
      <FinalizeModal
        open={openFinalizeModal}
        onClose={handleCloseFinalizeModal}
        onSubmit={handleFinalizeSubmit}
        rut={companyData.rut as string}
        razonSocial={companyData.name as string}
      />
      <Dialog open={openRowCreate} onClose={handleCloseRowCreate}>
        <DialogTitle>Agregando una nueva fila</DialogTitle>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '90%' },
            mb: 2
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleRowSubmit}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  La fila que se agregará se ubicará en el índice:{' '}
                  <b>{rowCreationNewIndex}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="account"
                  label="Cuenta"
                  defaultValue=""
                  required
                  fullWidth
                  variant="outlined"
                  placeholder="Ingrese el nombre de la cuenta"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  defaultValue={
                    accountClassesOptions.find(
                      (option) => option.value === '001'
                    ) || null
                  }
                  onChange={(
                    _: React.SyntheticEvent,
                    newValue: AutocompleteValue<
                      OptionType,
                      false,
                      false,
                      false
                    > | null
                  ) => {
                    if (!newValue) return
                    setRowCreationUserClassCode(newValue.value)
                  }}
                  sx={{
                    '& + .MuiList-root': {
                      height: 'auto'
                    },
                    '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                      color: 'white',
                      backgroundColor: neutral[500]
                    },
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                      {
                        color: 'white',
                        backgroundColor: neutral[700]
                      },
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true'].Mui-focused":
                      {
                        color: 'white',
                        backgroundColor: neutral[600]
                      },
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='false'].Mui-focused":
                      {
                        color: 'white',
                        backgroundColor: neutral[300]
                      }
                  }}
                  groupBy={(option) => option.parent}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value &&
                    option.parent === value.parent
                  }
                  options={accountClassesOptions}
                  getOptionLabel={(option: OptionType) => option.label}
                  renderInput={(params) => (
                    <TextField
                      label="Cuenta Bci"
                      variant="outlined"
                      name={`dialog-inputComplete`}
                      {...params}
                    />
                  )}
                  renderGroup={(params) => {
                    return (
                      <MenuList key={params.key} sx={{ p: 0 }}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems sx={{ p: 0 }}>{params.children}</GroupItems>
                      </MenuList>
                    )
                  }}
                />
              </Grid>
              {dates.map((date) => (
                <Grid item xs={6} key={date}>
                  <NumericField
                    name={`value_${date}`}
                    label={`Valor ${date}`}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ pr: 5 }}>
            <Button
              onClick={handleCloseRowCreate}
              color="error"
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Agregar
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Grid>
  )
}

export default BalanceDetailsClassified
