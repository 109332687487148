import axios from 'axios'
import { isEmpty, isEqual } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import CancelIcon from '@mui/icons-material/Close'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SaveIcon from '@mui/icons-material/Save'
import SearchIcon from '@mui/icons-material/Search'
import {
  Alert,
  Box,
  Grid,
  LinearProgress,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridValidRowModel
} from '@mui/x-data-grid'
import { esES } from '@mui/x-data-grid/locales'

import useDebounce from '@/hooks/useDebounce'
import {
  companyCreateRequest,
  companyListRequest,
  companyNameGetSIIRequest,
  companyUpdateRequest
} from '@/requests/companies'
import { CompanyType } from '@/types/Company'
import { formatRut, rutValid } from '@/utils'

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-filler': {
    backgroundColor: '#010B1BDE',
    color: '#FFFFFF'
  },
  '& .MuiDataGrid-columnHeader': {
    borderBottom: 'none',
    backgroundColor: '#010B1BDE',
    color: '#FFFFFF',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 1,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600 // Ensuring title matches the fontWeight
    }
  },
  '& .MuiDataGrid-scrollbarFiller--header': {
    backgroundColor: '#010B1BDE'
  },
  [`& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell`]: {
    height: '35px'
  },
  '& .MuiDataGrid-cell': {
    borderTop: '1px solid #E9ECFB',
    height: '35px'
  },
  '& .MuiDataGrid-cell-rut': {
    fontFeatureSettings: "'tnum'"
  }
}))

const CompaniesTable = () => {
  const [companies, setCompanies] = useState<CompanyType[]>([])
  const [newCompany, setNewCompany] = useState<CompanyType>({
    uuid: '',
    rut: '',
    name: ''
  })
  const [isRutValid, setIsRutValid] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNameFieldDisabled, setIsNameFieldDisabled] = useState(true)
  const [rut, setRut] = useState('')
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
  const [loadingNameSuggestion, setLoadingNameSuggestion] = useState(false)
  const [isCompanyNotInit, setIsCompanyNotInit] = useState(false)
  const debouncedRut = useDebounce(rut, 850)
  const debouncedNewCompanyRut = useDebounce(newCompany.rut, 850)

  useEffect(() => {
    companyListRequest(debouncedRut)
      .then((response) => setCompanies(response.data))
      .catch((error) => console.error(error))
  }, [debouncedRut, debouncedNewCompanyRut])

  useEffect(() => {
    let isMounted = true
    const getCompanyNameSuggestion = async (rutNewCompany: string) => {
      if (!rutNewCompany || !rutValid(rutNewCompany)) {
        return ''
      }
      setLoadingNameSuggestion(true)
      try {
        const response = await companyNameGetSIIRequest(rutNewCompany)
        if (!isMounted) {
          return ''
        }
        setNewCompany((prevCompany) => ({
          ...prevCompany,
          name: response.data.name
        }))
        if (isEmpty(response.data.name)) {
          setIsCompanyNotInit(true)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoadingNameSuggestion(false)
        setIsNameFieldDisabled(false)
      }
    }
    getCompanyNameSuggestion(debouncedNewCompanyRut)
    return () => {
      isMounted = false
    }
  }, [debouncedNewCompanyRut])

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ]
      }
    },
    { field: 'uuid', headerName: 'uuid', width: 200 },
    {
      field: 'rut',
      headerName: 'RUT',
      width: 150,
      editable: true,
      cellClassName: 'MuiDataGrid-cell-rut'
    },
    { field: 'name', headerName: 'Nombre', width: 450, editable: true },
    { field: 'activities', headerName: 'Actividades', width: 450 }
  ]

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'rut') {
      const isValid = rutValid(value)
      setIsRutValid(isValid)
      const formattedValue = formatRut(value)
      setNewCompany((prevCompany) => ({
        ...prevCompany,
        [name]: formattedValue
      }))
      setIsCompanyNotInit(false)
    } else {
      setNewCompany((prevCompany) => ({ ...prevCompany, [name]: value }))
    }
  }

  async function handleRowUpdate(
    updatedRow: GridValidRowModel,
    originalRow: GridValidRowModel
  ) {
    if (isEqual(updatedRow, originalRow)) {
      return originalRow
    }

    try {
      const response = await companyUpdateRequest(updatedRow.uuid, {
        uuid: updatedRow.uuid,
        rut: updatedRow.rut,
        name: updatedRow.name
      })
      if (response.status === 200) {
        toast.success('Empresa actualizada correctamente', {
          toastId: 'success-company-update',
          position: toast.POSITION.BOTTOM_RIGHT
        })
        return updatedRow
      }
      return originalRow
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(`Error: ${error.response?.data.message}`, {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
      throw error
    }
  }

  const handleProcessRowUpdateError = (error: Error) => {
    console.error('An error occurred while updating the row:', error)
    // Here you can add more error handling logic, such as displaying a notification to the user
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })
  }

  const handleAddCompany = () => {
    companyCreateRequest(newCompany)
      .then(() => {
        companyListRequest()
          .then((response) => setCompanies(response.data))
          .catch((error) => console.error(error))
        setNewCompany({ uuid: '', rut: '', name: '' })
        setIsModalOpen(false)
      })
      .catch((error) => {
        if (error.response.data.extra.fields.rut) {
          toast.error(
            `Hubo un problema al crear la empresa. Error: ${error.response.data.extra.fields.rut}`,
            {
              toastId: 'error-token',
              position: toast.POSITION.BOTTOM_RIGHT
            }
          )
        } else {
          toast.error(
            `Hubo un problema al crear la empresa. Error: ${
              (error as Error).message
            }`,
            {
              toastId: 'error-token',
              position: toast.POSITION.BOTTOM_RIGHT
            }
          )
        }
        console.error(error)
      })
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setNewCompany({ uuid: '', rut: '', name: '' })
    setIsModalOpen(false)
    setIsNameFieldDisabled(true)
    setIsCompanyNotInit(false)
  }

  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Typography variant="h3">Empresas</Typography>
        <Box mr={2} display="flex" gap={2}>
          <TextField
            label="Filtrar por RUT"
            size="small"
            onChange={(e) => setRut(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon sx={{ color: 'grey' }} />
            }}
          />
          <Button variant="contained" onClick={handleModalOpen}>
            Crear empresa
          </Button>
        </Box>
      </Grid>
      <Box mt={2} sx={{ height: '75vh', width: '100%' }}>
        <StyledDataGrid
          rows={companies}
          columns={columns}
          getRowId={(row) => row.uuid}
          columnHeaderHeight={35}
          rowHeight={35}
          editMode="row"
          initialState={{
            columns: {
              columnVisibilityModel: {
                uuid: false // Hide the uuid column, the other columns will remain visible
              }
            }
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          processRowUpdate={handleRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
        />
      </Box>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '52%',
            left: '52%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '95%' },
              mb: 2
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h5">Agregar una nueva empresa</Typography>
            <TextField
              type="text"
              name="rut"
              error={!isRutValid && newCompany.rut !== ''}
              helperText={
                !isRutValid && newCompany.rut !== '' ? 'RUT inválido' : ''
              }
              value={formatRut(newCompany.rut)}
              onChange={handleInputChange}
              label="RUT"
            />
            {loadingNameSuggestion && (
              <Box>
                <LinearProgress />
                <Typography variant="body2" color="textSecondary">
                  Buscando razón social...
                </Typography>
              </Box>
            )}
            {isCompanyNotInit && (
              <Alert severity="warning">
                RUT no cuenta con actividades vigentes en SII
              </Alert>
            )}
            <TextField
              type="text"
              name="name"
              value={newCompany.name}
              onChange={handleInputChange}
              label="Razón social"
              disabled={isNameFieldDisabled}
            />
            <Alert severity="info">
              Una vez guardada la empresa se intentará buscar automáticamente
              las actividades desde el SII.
            </Alert>
          </Box>
          <Grid container justifyContent="center" sx={{ mt: 2 }} spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="error"
                onClick={handleModalClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddCompany}
                disabled={!newCompany.name || !newCompany.rut || !isRutValid}
              >
                Crear empresa
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  )
}

export default CompaniesTable
