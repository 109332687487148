import { Link } from 'react-router-dom'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'

import logoBci from '@/assets/white_bci_logo.png'
import { SideBarMenu } from '@/components/MenuListNav'
import { Scrollbar } from '@/components/Scrollbar'
import privateRoutes from '@/router/private'
import { neutral } from '@/theme/colors'

const drawerWidth = 279

interface SidebarProps {
  open: boolean
  handleSidebar: () => void
}

const Sidebar = ({ open, handleSidebar }: SidebarProps) => {
  return (
    <Drawer
      variant="persistent"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: neutral[900],
          color: 'white'
        }
      }}
      sx={{
        width: drawerWidth,
        height: '100vh',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          },
          '& .simplebar-scrollbar:before': {
            background: 'neutral.400'
          }
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1]
          }}
        >
          <IconButton color="inherit" size="large">
            <Link
              to="/statements"
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              <img src={logoBci} alt="logo" style={{ height: 40 }} />
            </Link>
          </IconButton>
          <IconButton onClick={handleSidebar}>
            <ChevronLeftIcon sx={{ color: 'white' }} />
          </IconButton>
        </Toolbar>
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0
            }}
          >
            <SideBarMenu routes={privateRoutes} />
          </Stack>
        </Box>
      </Scrollbar>
    </Drawer>
  )
}

export default Sidebar
