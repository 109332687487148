import { useEffect, useRef, useState } from 'react'

import { cloneDeep } from 'lodash'
import { toast } from 'react-toastify'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import RedoIcon from '@mui/icons-material/Redo'
import UndoIcon from '@mui/icons-material/Undo'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {
  CellChange,
  CellTemplates,
  Column,
  DefaultCellTypes,
  DropdownCell,
  MenuOption,
  NumberCell,
  ReactGrid,
  Row,
  SelectionMode,
  TextCell
} from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'

import {
  ISelectCell,
  SelectCellTemplate
} from '@/components/SelectCustomTemplate'
import { statementItemsUpdateRequest } from '@/requests/accounts'
import { CuentaAnyType, CuentaType } from '@/types/Cuenta'
import { TOTAL_ACCOUNT_CLASS, convertAttributeToExternal } from '@/utils'

import {
  bottomLine,
  nonEditable,
  numberCell,
  numberVerifyCell,
  showZero,
  textCell
} from './cells'
import './grid.css'

type OptionType = {
  value: string
  label: string
  parent: string
}

type Id = number | string

interface GridEditProps {
  tableData: CuentaType[]
  setTableData: (data: CuentaType[]) => void
  accountClassesOptions: OptionType[]
  uuid: string
  handleOpenRowCreate: () => void
  setRowCreationNewIndex: (index: number) => void
  newRowIndex: number | null
  statementValidated: boolean
}

const TOTAL_VALIDATION = 'total-validation'
const COLUMN_WIDTH_WIDE = 300
const COLUMN_WIDTH_NARROW = 150
const ROW_HEIGHT = 33

const isMacOs = () => window.navigator.userAgent.includes('Mac')

const getColumns = (allColumns: boolean): Column[] => {
  const columns = [
    { columnId: 'cuenta', width: COLUMN_WIDTH_WIDE, resizable: true },
    { columnId: 'user_class_code', width: COLUMN_WIDTH_WIDE, resizable: true }
  ]

  if (allColumns) {
    columns.push(
      { columnId: 'debitos', width: COLUMN_WIDTH_NARROW, resizable: true },
      { columnId: 'creditos', width: COLUMN_WIDTH_NARROW, resizable: true },
      { columnId: 'deudor', width: COLUMN_WIDTH_NARROW, resizable: true },
      { columnId: 'acreedor', width: COLUMN_WIDTH_NARROW, resizable: true }
    )
  }

  columns.push(
    { columnId: 'activo', width: COLUMN_WIDTH_NARROW, resizable: true },
    { columnId: 'pasivo', width: COLUMN_WIDTH_NARROW, resizable: true },
    { columnId: 'perdida', width: COLUMN_WIDTH_NARROW, resizable: true },
    { columnId: 'ganancia', width: COLUMN_WIDTH_NARROW, resizable: true }
  )

  return columns
}

const getHeaderRow = (allColumns: boolean): Row => {
  const cells = [
    {
      type: 'header',
      text: 'Cuentas',
      style: {
        background: '#010B1BDE',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontWeight: 'bold'
      }
    },
    {
      type: 'header',
      text: 'Cuentas Bci',
      style: {
        background: '#010B1BDE',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontWeight: 'bold'
      }
    }
  ]

  if (allColumns) {
    cells.push(
      {
        type: 'header',
        text: 'Débitos',
        style: {
          background: '#010B1BDE',
          color: '#FFFFFF',
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }
      },
      {
        type: 'header',
        text: 'Créditos',
        style: {
          background: '#010B1BDE',
          color: '#FFFFFF',
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }
      },
      {
        type: 'header',
        text: 'Deudor',
        style: {
          background: '#010B1BDE',
          color: '#FFFFFF',
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }
      },
      {
        type: 'header',
        text: 'Acreedor',
        style: {
          background: '#010B1BDE',
          color: '#FFFFFF',
          textTransform: 'uppercase',
          fontWeight: 'bold'
        }
      }
    )
  }

  cells.push(
    {
      type: 'header',
      text: 'Activo',
      style: {
        background: '#010B1BDE',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontWeight: 'bold'
      }
    },
    {
      type: 'header',
      text: 'Pasivo',
      style: {
        background: '#010B1BDE',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontWeight: 'bold'
      }
    },
    {
      type: 'header',
      text: 'Perdida',
      style: {
        background: '#010B1BDE',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontWeight: 'bold'
      }
    },
    {
      type: 'header',
      text: 'Ganancia',
      style: {
        background: '#010B1BDE',
        color: '#FFFFFF',
        textTransform: 'uppercase',
        fontWeight: 'bold'
      }
    }
  )

  return {
    rowId: 'header',
    height: ROW_HEIGHT,
    cells
  } as Row
}

const gettingValidatedRow = (currentTableData: CuentaType[]): CuentaType => {
  const tableDataCopy = cloneDeep(currentTableData)
  const lastRow = tableDataCopy
    .filter((row) => row.user_class_code === TOTAL_ACCOUNT_CLASS)
    .pop()
  if (!lastRow) {
    return {
      uuid: TOTAL_VALIDATION,
      cuenta: 'Verificación',
      index: 0,
      user_class_code: 'total-validation',
      debitos: 0,
      creditos: 0,
      deudor: 0,
      acreedor: 0,
      activo: 0,
      pasivo: 0,
      perdida: 0,
      ganancia: 0
    }
  }

  const total = tableDataCopy
    .filter(
      (row) =>
        row.user_class_code !== TOTAL_VALIDATION && // filter out the validation row
        row.user_class_code !== TOTAL_ACCOUNT_CLASS // filter out the total row
    )
    .reduce(
      (acc, curr) => {
        acc.activo += curr.activo
        acc.pasivo += curr.pasivo
        acc.perdida += curr.perdida
        acc.ganancia += curr.ganancia
        return acc
      },
      {
        activo: 0,
        pasivo: 0,
        perdida: 0,
        ganancia: 0
      }
    )
  const diff = {
    activo: total.activo - lastRow.activo,
    pasivo: total.pasivo - lastRow.pasivo,
    perdida: total.perdida - lastRow.perdida,
    ganancia: total.ganancia - lastRow.ganancia
  }

  return {
    uuid: TOTAL_VALIDATION,
    cuenta: 'Verificación Total',
    index: lastRow.index + 1,
    user_class_code: TOTAL_VALIDATION,
    debitos: 0,
    creditos: 0,
    deudor: 0,
    acreedor: 0,
    activo: diff.activo,
    pasivo: diff.pasivo,
    perdida: diff.perdida,
    ganancia: diff.ganancia
  }
}

const GridEdit: React.FC<GridEditProps> = ({
  tableData,
  setTableData,
  accountClassesOptions,
  uuid,
  handleOpenRowCreate,
  setRowCreationNewIndex,
  newRowIndex,
  statementValidated = false
}) => {
  const reactRef = useRef<ReactGrid>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [allColumns, setAllColumns] = useState<boolean>(false)
  const [showDisabled, setShowDisabled] = useState(false)
  const [columns, setColumns] = useState<Column[]>(getColumns(allColumns))
  const [openOptions, setOpenOptions] = useState<boolean>(false)
  const [cellChangesIndex, setCellChangesIndex] = useState<number>(() => -1)
  const [cellChanges, setCellChanges] = useState<
    CellChange<NumberCell | TextCell | DropdownCell>[][]
  >(() => [])
  const headerRow = getHeaderRow(allColumns)

  const accountClassesOptionsMap = accountClassesOptions.reduce(
    (acc: { [key: string]: string }, option: OptionType) => {
      acc[option.value] = option.label
      return acc
    },
    {}
  )

  const toggleDisabled = () => {
    setShowDisabled((prev) => !prev)
  }

  const toggleAllColumns = () => {
    setAllColumns((prev) => !prev)
  }

  const handleClickCloseOptions = () => {
    setOpenOptions(false)
    setAnchorEl(null)
  }

  const handleClickOpenOptions = (event: React.MouseEvent<HTMLElement>) => {
    setOpenOptions(true)
    setAnchorEl(event.currentTarget)
  }

  // Update columns state whenever allColumns changes
  useEffect(() => {
    setColumns(getColumns(allColumns))
  }, [allColumns])

  const toggleRowDisabled = (newRowIndex: number) => {
    const newTableData = tableData.map((row, idx) => {
      if (idx === newRowIndex) {
        return { ...row, disabled: !row.disabled }
      }
      return row
    })
    setTableData(newTableData)
  }

  /**
   * Applies new changes to the data.
   * @param changes - The changes to apply.
   * @param prevData - The previous data.
   * @param usePrevValue - Whether to use the previous value.
   * @returns The updated data.
   */
  const applyNewChangesToData = (
    changes: CellChange[],
    prevData: CuentaAnyType[],
    usePrevValue: boolean = false
  ): CuentaType[] => {
    try {
      const dataCopy = cloneDeep(prevData)
      const batchUpdates: Partial<CuentaType>[] = []
      // updating frontend
      changes.forEach((change) => {
        const dataIndex = change.rowId as number
        const fieldName = change.columnId as string
        const cell = (usePrevValue ? change.previousCell : change.newCell) as
          | DefaultCellTypes
          | ISelectCell
        let cellValue: string | number = ''

        if (cell.type === 'number') {
          cellValue = cell.value || 0
          dataCopy[dataIndex][fieldName] = cellValue
        } else if (cell.type === 'text') {
          cellValue = cell.text
          dataCopy[dataIndex][fieldName] = `${cellValue}`
        } else if (cell.type === 'select') {
          cellValue = cell.selectedValue as string
          dataCopy[dataIndex][fieldName] = cellValue
        } else {
          console.log('cell type not supported')
        }

        const fieldChanged: Record<string, string | number> = {
          uuid: prevData[dataIndex]['uuid'] as string,
          [fieldName]: cellValue
        }
        if (cellValue !== '') {
          batchUpdates.push(
            convertAttributeToExternal(
              fieldChanged,
              fieldName as keyof CuentaType
            ) as Partial<CuentaType>
          )
        }
      })
      // updating backend
      if (batchUpdates.length > 0) {
        statementItemsUpdateRequest(uuid, batchUpdates).catch((error) => {
          toast.error(
            `Hubo un problema al actualizar el item. Error: ${
              (error as Error).message
            }`,
            {
              toastId: 'error-token'
            }
          )
        })
      }
      return [...dataCopy] as CuentaType[]
    } catch (e) {
      console.error('Error applying changes:', e)
      return prevData as CuentaType[]
    }
  }

  const undoChanges = (
    changes: CellChange<TextCell | NumberCell | DropdownCell>[],
    prevData: CuentaType[]
  ): CuentaType[] => {
    const newData = applyNewChangesToData(changes, prevData, true)
    setCellChangesIndex((prevIndex) => prevIndex - 1)
    return newData
  }

  const redoChanges = (
    changes: CellChange<TextCell | NumberCell | DropdownCell>[],
    prevData: CuentaType[]
  ): CuentaType[] => {
    const newData = applyNewChangesToData(changes, prevData)
    setCellChangesIndex((prevIndex) => prevIndex + 1)
    return newData
  }

  const applyChanges = (
    changes: CellChange<NumberCell | TextCell | DropdownCell>[],
    prevData: CuentaType[]
  ) => {
    const newData = applyNewChangesToData(changes, prevData)
    setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes])
    setCellChangesIndex(cellChangesIndex + 1)
    return newData as CuentaType[]
  }

  const handleChanges = (changes: CellChange[]) => {
    if (statementValidated) return
    const isValid = validateChanges(
      changes as CellChange<NumberCell | TextCell | ISelectCell>[]
    )
    if (!isValid) {
      return
    }
    const newTableData = applyChanges(
      changes as CellChange<NumberCell | TextCell | DropdownCell>[],
      tableData
    )
    setTableData(newTableData)
  }

  const validateChanges = (
    changes: CellChange<NumberCell | TextCell | ISelectCell>[]
  ) => {
    for (const change of changes) {
      if (change.columnId === 'user_class_code') {
        if (
          !accountClassesOptionsMap[
            (change.newCell as ISelectCell).selectedValue as string
          ]
        ) {
          toast.error(
            `La clase de cuenta "${
              (change.newCell as ISelectCell).selectedValue
            }" no es válida. Por favor, seleccione una clase de cuenta válida.`,
            {
              toastId: 'error-token'
            }
          )
          return false
        }
      }
    }
    return true
  }

  const handleUndoChanges = () => {
    if (cellChangesIndex >= 0) {
      const undoData = undoChanges(cellChanges[cellChangesIndex], tableData)
      setTableData(undoData)
    }
  }

  const handleRedoChanges = () => {
    if (cellChangesIndex + 1 <= cellChanges.length - 1) {
      const redoData = redoChanges(cellChanges[cellChangesIndex + 1], tableData)
      setTableData(redoData)
    }
  }

  const createCells = (data: CuentaType, idx: number, allColumns: boolean) => {
    const baseCells = [
      {
        type: 'text',
        nonEditable: true,
        text: data.cuenta,
        className: idx % 2 === 0 ? 'even-row' : 'odd-row',
        style: newRowIndex === idx ? { background: '#c1f1c1' } : {}
      },
      {
        type: 'select',
        inputValue: accountClassesOptionsMap[data.user_class_code],
        selectedValue: data.user_class_code,
        values: accountClassesOptions,
        className:
          idx === newRowIndex
            ? 'green-row'
            : idx % 2 === 0
            ? 'even-row'
            : 'odd-row'
      }
    ]

    const additionalCells = allColumns
      ? [
          {
            type: 'number',
            value: data.debitos,
            format: Intl.NumberFormat('de', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            }),
            className: idx % 2 === 0 ? 'even-row' : 'odd-row',
            style: { background: '#FFF1F0' }
          },
          {
            type: 'number',
            value: data.creditos,
            format: Intl.NumberFormat('de', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            }),
            className: idx % 2 === 0 ? 'even-row' : 'odd-row',
            style: { background: '#FFF1F0' }
          },
          {
            type: 'number',
            value: data.deudor,
            format: Intl.NumberFormat('de', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            }),
            className: idx % 2 === 0 ? 'even-row' : 'odd-row',
            style: { background: '#FFF1F0' }
          },
          {
            type: 'number',
            value: data.acreedor,
            format: Intl.NumberFormat('de', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2
            }),
            className: idx % 2 === 0 ? 'even-row' : 'odd-row',
            style: { background: '#FFF1F0' }
          }
        ]
      : []

    const endCells = [
      {
        type: 'number',
        value: data.activo,
        format: Intl.NumberFormat('de', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        className: idx % 2 === 0 ? 'even-row' : 'odd-row',
        style: newRowIndex === idx ? { background: '#c1f1c1' } : {}
      },
      {
        type: 'number',
        value: data.pasivo,
        format: Intl.NumberFormat('de', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        className: idx % 2 === 0 ? 'even-row' : 'odd-row',
        style: newRowIndex === idx ? { background: '#c1f1c1' } : {}
      },
      {
        type: 'number',
        value: data.perdida,
        format: Intl.NumberFormat('de', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        className: idx % 2 === 0 ? 'even-row' : 'odd-row',
        style: newRowIndex === idx ? { background: '#c1f1c1' } : {}
      },
      {
        type: 'number',
        value: data.ganancia,
        format: Intl.NumberFormat('de', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        }),
        className: idx % 2 === 0 ? 'even-row' : 'odd-row',
        style: newRowIndex === idx ? { background: '#c1f1c1' } : {}
      }
    ]

    return [...baseCells, ...additionalCells, ...endCells]
  }

  // * This will create the spreadsheet planning style
  // parts:
  // - header row
  // - data rows
  // - total row
  // - validation row
  const getRows = (
    cuentas: CuentaType[],
    validatingRow: CuentaType
  ): Row<DefaultCellTypes | ISelectCell>[] => [
    headerRow,
    ...cuentas
      .slice(0, -1)
      .map<Row<DefaultCellTypes | ISelectCell> | null>((data, idx) => {
        // ? Skip disabled rows
        if (!showDisabled && data.disabled) {
          return null // Skip this row
        }
        return {
          rowId: idx,
          height: ROW_HEIGHT,
          cells: createCells(data, idx, allColumns) as DefaultCellTypes[]
        }
      })
      // ? Filter out null rows
      .filter(
        (row): row is Row<DefaultCellTypes | ISelectCell> => row !== null
      ),
    {
      rowId: cuentas.length - 1,
      height: ROW_HEIGHT,
      cells: [
        bottomLine(
          nonEditable(
            textCell(cuentas[cuentas.length - 1].cuenta, 'even-row', {
              background: '#C8D2E4'
            })
          )
        ),
        {
          type: 'select',
          inputValue:
            accountClassesOptionsMap[
              cuentas[cuentas.length - 1].user_class_code
            ],
          selectedValue: cuentas[cuentas.length - 1].user_class_code,
          values: accountClassesOptions,
          style: {
            background: '#C8D2E4'
          }
        },
        ...(allColumns
          ? [
              bottomLine(
                numberCell(cuentas[cuentas.length - 1].debitos, 'even-row', {
                  background: '#C8D2E4'
                })
              ),
              bottomLine(
                numberCell(cuentas[cuentas.length - 1].creditos, 'even-row', {
                  background: '#C8D2E4'
                })
              ),
              bottomLine(
                numberCell(cuentas[cuentas.length - 1].deudor, 'even-row', {
                  background: '#C8D2E4'
                })
              ),
              bottomLine(
                numberCell(cuentas[cuentas.length - 1].acreedor, 'even-row', {
                  background: '#C8D2E4'
                })
              )
            ]
          : []),
        bottomLine(
          showZero(
            numberCell(cuentas[cuentas.length - 1].activo, 'even-row', {
              background: '#C8D2E4'
            })
          )
        ),
        bottomLine(
          showZero(
            numberCell(cuentas[cuentas.length - 1].pasivo, 'even-row', {
              background: '#C8D2E4'
            })
          )
        ),
        bottomLine(
          showZero(
            numberCell(cuentas[cuentas.length - 1].perdida, 'even-row', {
              background: '#C8D2E4'
            })
          )
        ),
        bottomLine(
          showZero(
            numberCell(cuentas[cuentas.length - 1].ganancia, 'even-row', {
              background: '#C8D2E4'
            })
          )
        )
      ]
    },
    {
      rowId: TOTAL_VALIDATION,
      height: ROW_HEIGHT,
      cells: [
        bottomLine(
          nonEditable(
            textCell(validatingRow.cuenta, 'even-row', {
              background: '#010B1BDE',
              color: '#FFFFFF'
            })
          )
        ),
        bottomLine(
          nonEditable(
            textCell('', 'even-row', {
              background: '#010B1BDE'
            })
          )
        ),
        ...(allColumns
          ? [
              bottomLine(
                nonEditable(
                  textCell('', 'even-row', {
                    background: '#010B1BDE'
                  })
                )
              ),
              bottomLine(
                nonEditable(
                  textCell('', 'even-row', {
                    background: '#010B1BDE'
                  })
                )
              ),
              bottomLine(
                nonEditable(
                  textCell('', 'even-row', {
                    background: '#010B1BDE'
                  })
                )
              ),
              bottomLine(
                nonEditable(
                  textCell('', 'even-row', {
                    background: '#010B1BDE'
                  })
                )
              )
            ]
          : []),
        bottomLine(
          nonEditable(
            showZero(
              numberVerifyCell(validatingRow.activo, 'even-row', {
                background: '#010B1BDE'
              })
            )
          )
        ),
        bottomLine(
          nonEditable(
            showZero(
              numberVerifyCell(validatingRow.pasivo, 'even-row', {
                background: '#010B1BDE'
              })
            )
          )
        ),
        bottomLine(
          nonEditable(
            showZero(
              numberVerifyCell(validatingRow.perdida, 'even-row', {
                background: '#010B1BDE'
              })
            )
          )
        ),
        bottomLine(
          nonEditable(
            showZero(
              numberVerifyCell(validatingRow.ganancia, 'even-row', {
                background: '#010B1BDE'
              })
            )
          )
        )
      ]
    }
  ]

  const handleColumnResize = (ci: Id, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci)
      const resizedColumn = prevColumns[columnIndex]
      const updatedColumn = { ...resizedColumn, width }
      prevColumns[columnIndex] = updatedColumn
      return [...prevColumns]
    })
  }

  const handleKeyPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((!isMacOs() && e.ctrlKey) || e.metaKey) {
      switch (e.key) {
        case 'z':
          if (e.shiftKey) {
            handleRedoChanges()
          } else {
            handleUndoChanges()
          }
          return
        case 'y':
          if (!isMacOs()) {
            handleRedoChanges()
          }
          return
      }
    }
  }

  const CustomCellTemplates: CellTemplates = {
    select: new SelectCellTemplate()
  }

  const handleContextMenu = (
    _selectedRowIds: Id[],
    _selectedColIds: Id[],
    _selectionMode: SelectionMode,
    menuOptions: MenuOption[]
  ): MenuOption[] => {
    menuOptions = [
      ...menuOptions,
      {
        id: 'addRow',
        label: 'Agregar fila debajo',
        handler: () => {
          const focusedCell = reactRef.current?.state.focusedLocation
          const newIndex = (focusedCell?.row.rowId as number) + 1
          handleOpenRowCreate()
          setRowCreationNewIndex(newIndex)
        }
      },
      {
        id: 'disabledRow',
        label: 'Habilitar/Deshabilitar fila',
        handler: () => {
          const focusedCell = reactRef.current?.state.focusedLocation
          const newIndex = focusedCell?.row.rowId as number
          toggleRowDisabled(newIndex)
        }
      }
    ]

    return menuOptions
  }

  // validation row
  const validatingRow = gettingValidatedRow(tableData)

  // creating rows
  const rows = getRows(tableData, validatingRow)

  return (
    <Box sx={{ margin: '0 auto' }}>
      {!statementValidated && (
        <Paper
          elevation={3}
          sx={{
            padding: 1,
            borderRadius: '15px 15px 0 0'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ButtonGroup variant="outlined" aria-label="button group">
                <Tooltip title="Deshacer cambios">
                  <span>
                    <Button
                      onClick={handleUndoChanges}
                      disabled={cellChangesIndex === -1}
                    >
                      <UndoIcon />
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title="Rehacer cambios">
                  <span>
                    <Button
                      onClick={handleRedoChanges}
                      disabled={cellChangesIndex === cellChanges.length - 1}
                    >
                      <RedoIcon />
                    </Button>
                  </span>
                </Tooltip>
              </ButtonGroup>
              <Typography variant="body1" gutterBottom sx={{ marginLeft: 2 }}>
                Aquí puede realizar acciones como
                <br /> copiar, pegar y cortar datos.
              </Typography>
            </Box>
            <Box>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openOptions ? 'long-menu' : undefined}
                aria-expanded={openOptions ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClickOpenOptions}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={openOptions}
                onClose={handleClickCloseOptions}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: 30 * 4.5,
                      width: '30ch',
                      transform: 'translateX(-95%)' // Shift the menu to the left
                    }
                  }
                }}
              >
                <MenuItem
                  onClick={() => {
                    toggleDisabled()
                    handleClickCloseOptions()
                  }}
                >
                  {showDisabled
                    ? 'Ocultar Filas Deshabilitadas'
                    : 'Mostrar Filas Deshabilitadas'}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    toggleAllColumns()
                    handleClickCloseOptions()
                  }}
                >
                  {allColumns
                    ? 'Ocultar columnas'
                    : 'Mostrar todas las columnas'}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Paper>
      )}
      <Box
        sx={{
          maxWidth: {
            xs: '320px', // 100% for extra-small screens
            sm: '640px', // 90% for small screens
            md: '900px', // 80% for medium screens
            lg: '1100px', // 70% for large screens
            xl: '1400px' // 60% for extra-large screens
          },
          maxHeight: {
            xs: '60vh', // 60vh for extra-small screens
            md: '70vh' // 70vh for medium and larger screens
          },
          overflow: 'scroll'
        }}
      >
        <Box onKeyDown={handleKeyPressed}>
          <ReactGrid
            ref={reactRef}
            columns={columns}
            rows={rows}
            enableRangeSelection
            enableFillHandle
            onCellsChanged={handleChanges}
            onColumnResized={handleColumnResize}
            customCellTemplates={CustomCellTemplates}
            onContextMenu={handleContextMenu}
            stickyTopRows={1}
            stickyBottomRows={2}
            labels={{
              legacyBrowserHeader:
                'Por favor, actualiza a un navegador moderno.',
              legacyBrowserText:
                'Tu navegador actual no puede ejecutar nuestro contenido, por favor asegúrate de que tu navegador está completamente actualizado o prueba con un navegador diferente. Recomendamos encarecidamente el uso de la versión más reciente de Google Chrome, Microsoft Edge, Firefox, Safari y Opera.',
              copyLabel: 'Copiar',
              cutLabel: 'Cortar',
              pasteLabel: 'Pegar',
              appleMobileDeviceContextMenuPasteAlert:
                'Usa ⌘ + c para copiar, ⌘ + x para cortar y ⌘ + v para pegar.',
              otherBrowsersContextMenuPasteAlert:
                'Usa ctrl + c para copiar, ctrl + x para cortar y ctrl + v para pegar.',
              actionNotSupported:
                'Esta acción no es compatible con este navegador.'
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default GridEdit
