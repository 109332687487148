import { createContext } from 'react'

export interface AuthContextType {
  token: string | null
  isLoggedIn: boolean
  isAuthenticated: boolean
  user: Record<string, unknown> | null
  groups: string[]
  setToken: (token: string | null) => void
  login: (username: string, password: string) => Promise<boolean>
  logout: () => void
  validateToken: (token: string) => boolean
  refreshAccessToken: () => Promise<string>
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export default AuthContext
