import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export const USER_API = import.meta.env.VITE_API_URL as string

export const client = (options: AxiosRequestConfig) => {
  const defaultOptions: AxiosRequestConfig = {
    baseURL: USER_API,
    timeout: 60 * 2 * 1000, // Waiting 2 min
    ...options
    // there's an interceptor that adds the token to the request in src/components/RequestInterceptor/index.tsx
  }

  return {
    get: (url: string, options?: AxiosRequestConfig): Promise<AxiosResponse> =>
      axios.get(url, { ...defaultOptions, ...options }),
    post: (
      url: string,
      data?: unknown,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse> =>
      axios.post(url, data, { ...defaultOptions, ...options }),
    put: (
      url: string,
      data?: unknown,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse> =>
      axios.put(url, data, { ...defaultOptions, ...options }),
    delete: (
      url: string,
      options: AxiosRequestConfig
    ): Promise<AxiosResponse> =>
      axios.delete(url, { ...defaultOptions, ...options })
  }
}

export const clientDownloadFile = (
  url: string,
  params: Record<string, unknown> = {},
  timeout: number = 60 * 10 * 1000 // 10 minutes
): Promise<AxiosResponse> => {
  // options should have url and params values
  return axios({
    baseURL: USER_API,
    method: 'get',
    responseType: 'blob',
    timeout,
    url,
    params
  })
}

export const postClientDownloadFile = (
  url: string,
  data: Record<string, unknown> = {},
  timeout: number = 60 * 10 * 1000 // 10 minutes
): Promise<AxiosResponse> => {
  return axios({
    baseURL: USER_API,
    method: 'post',
    responseType: 'blob',
    timeout,
    url,
    data
  })
}

export const clientUploadFile = (
  url: string,
  file: File,
  customParams: Record<string, unknown> = {},
  responseType: 'blob' | 'json' = 'json',
  timeout: number = 600000 // 10 minutes
): Promise<AxiosResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  for (const [key, value] of Object.entries(customParams)) {
    formData.append(key, value as string)
  }
  return axios({
    baseURL: USER_API,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    responseType,
    url,
    timeout
  })
}

export const clientUploadFiles = (
  url: string,
  files: File[],
  pages: string[] = [],
  customParams: Record<string, unknown> = {},
  responseType: 'blob' | 'json' = 'json',
  timeout: number = 600000 // 10 minutes
): Promise<AxiosResponse> => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })
  pages.forEach((page) => {
    formData.append('pages', page)
  })
  for (const [key, value] of Object.entries(customParams)) {
    formData.append(key, value as string)
  }
  return axios({
    baseURL: USER_API,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    responseType,
    url,
    timeout
  })
}
