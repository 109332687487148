import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  Box,
  Chip,
  Collapse,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { userStatsRequest } from '@/requests/reports'
import { UserStat } from '@/types/Reports'
import { secondsToHms } from '@/utils'
import 'dayjs/locale/es'

dayjs.locale('es') // setting locale globally

const ReportUserStats = () => {
  const [userStats, setUserStats] = useState<UserStat[]>([])
  const [range, setRange] = useState('last_week')
  const [loading, setLoading] = useState(false)
  const [expandedRow, setExpandedRow] = useState<string | null>(null)

  useEffect(() => {
    const fetchUserStats = async () => {
      setLoading(true) // Set loading to true before fetching
      try {
        const response = await userStatsRequest(range)
        setUserStats(response.data as UserStat[])
      } catch (error) {
        console.error('Error fetching user stats:', error)
      } finally {
        setLoading(false) // Set loading to false after fetching
      }
    }

    fetchUserStats()
  }, [range])

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newRange: string
  ) => {
    setRange(newRange)
  }

  const handleRowClick = (userId: string) => {
    setExpandedRow(expandedRow === userId ? null : userId)
  }

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Estadísticas de Usuario
      </Typography>
      <Box mb={2} display="flex" justifyContent="flex-end">
        <ToggleButtonGroup
          color="primary"
          value={range}
          size="small"
          exclusive
          onChange={handleChange}
          aria-label="Rango de tiempo de estadísticas de usuario"
        >
          <ToggleButton aria-label="Opción última semana" value="last_week">
            Última semana
          </ToggleButton>
          <ToggleButton aria-label="Opción un mes" value="4_weeks">
            1 Mes
          </ToggleButton>
          <ToggleButton aria-label="Opción tres meses" value="3_months">
            3 Meses
          </ToggleButton>
          <ToggleButton aria-label="Opción todo" value="everything">
            Todo
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {loading ? ( // Use the loading state to conditionally render
        <LinearProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Usuario</TableCell>
                <TableCell>Número Iniciado de Balances</TableCell>
                <TableCell>Número Aprobados de Balances</TableCell>
                <TableCell>Tiempo Mediana Procesado</TableCell>
                <TableCell>Tiempo Mediana Validación</TableCell>
                <TableCell>Tiempo Mediana Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userStats.map((userStat) => (
                <>
                  <TableRow
                    key={userStat.user}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Example color, adjust as needed
                        cursor: 'pointer'
                      }
                    }}
                    onClick={() => handleRowClick(userStat.user)}
                  >
                    <TableCell>{userStat.user}</TableCell>
                    <TableCell>{userStat.num_started}</TableCell>
                    <TableCell>{userStat.num_finished}</TableCell>
                    <TableCell>
                      {secondsToHms(userStat.avg_load_cicly)}
                    </TableCell>
                    <TableCell>
                      {secondsToHms(userStat.avg_validation_cicly)}
                    </TableCell>
                    <TableCell>
                      {secondsToHms(userStat.avg_total_cicly)}
                    </TableCell>
                  </TableRow>
                  {userStat.statements && userStat.statements.length > 0 && (
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          background: '#f9f9f9'
                        }}
                        colSpan={6}
                      >
                        <Collapse
                          in={expandedRow === userStat.user}
                          unmountOnExit
                        >
                          <Typography
                            variant="h6"
                            gutterBottom
                            component="div"
                            style={{ paddingTop: 10, textAlign: 'center' }}
                          >
                            Balances
                          </Typography>
                          <Box
                            margin={1}
                            style={{
                              maxHeight: '450px',
                              overflow: 'auto',
                              paddingBottom: 10
                            }}
                          >
                            <Table size="small" aria-label="statements">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 5
                                    }}
                                  >
                                    Empresa
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 5
                                    }}
                                  >
                                    RUT
                                  </TableCell>
                                  {/* <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      backgroundColor: '#f9f9f9',
                                      zIndex: 5
                                    }}
                                  >
                                    Moneda
                                  </TableCell> */}
                                  <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 5
                                    }}
                                  >
                                    Periodo
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 5
                                    }}
                                  >
                                    Fecha de Inicio
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 5
                                    }}
                                  >
                                    Estado
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 5
                                    }}
                                  >
                                    Procesado
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 5
                                    }}
                                  >
                                    Validación
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 5
                                    }}
                                  >
                                    Total
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      position: 'sticky',
                                      top: 0,
                                      zIndex: 5
                                    }}
                                  >
                                    Acciones
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {userStat.statements.map((statement) => (
                                  <TableRow key={statement.uuid}>
                                    <TableCell
                                      style={{
                                        maxWidth: '200px'
                                      }}
                                    >
                                      {statement.company_name}
                                    </TableCell>
                                    <TableCell>
                                      {statement.company_rut}
                                    </TableCell>
                                    {/* <TableCell>{statement.currency}</TableCell> */}
                                    <TableCell>{statement.date}</TableCell>
                                    <TableCell>
                                      {dayjs(statement.start_date).format(
                                        'DD [de] MMMM [de] YYYY, HH:mm'
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Chip
                                        label={statement.get_status_display}
                                        style={{
                                          backgroundColor:
                                            statement.status === 'EX'
                                              ? '#9c27b0' // Purple for "Extrayendo"
                                              : statement.status === 'PR'
                                              ? '#3f51b5' // Indigo for "Procesando"
                                              : statement.status === 'CL'
                                              ? '#009688' // Teal for "Clasificando"
                                              : statement.status === 'PA'
                                              ? '#FFE7BA' // Orange for "Aprobación pendiente"
                                              : statement.status === 'AP'
                                              ? '#D9F7BE' // Green for "Aprobado"
                                              : '#e0e0e0', // Grey for any other status
                                          color:
                                            statement.status === 'EX'
                                              ? '#000000' // Purple for "Extrayendo"
                                              : statement.status === 'PR'
                                              ? '#000000' // Indigo for "Procesando"
                                              : statement.status === 'CL'
                                              ? '#000000' // Teal for "Clasificando"
                                              : statement.status === 'PA'
                                              ? '#873800' // Orange for "Aprobación pendiente"
                                              : statement.status === 'AP'
                                              ? '#135200' // Green for "Aprobado"
                                              : '#e0e0e0' // Grey for any other status
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {secondsToHms(statement.load_cicly)}
                                    </TableCell>
                                    <TableCell>
                                      {secondsToHms(statement.validation_cicly)}
                                    </TableCell>
                                    <TableCell>
                                      {secondsToHms(statement.total_cicly)}
                                    </TableCell>
                                    <TableCell align="center">
                                      <Tooltip
                                        title="Ir al balance"
                                        placement="left"
                                      >
                                        <IconButton
                                          onClick={() =>
                                            window.open(
                                              `/statements/${statement.uuid}`,
                                              '_blank'
                                            )
                                          }
                                        >
                                          <ArrowForwardIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default ReportUserStats
