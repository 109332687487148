import { createTheme as createMuiTheme, Theme } from '@mui/material'

import { createComponents } from '@/theme/create-components'
import { createPalette } from '@/theme/create-palette'
import { createShadows } from '@/theme/create-shadows'
import { createTypography } from '@/theme/create-typography'

export function createTheme(): Theme {
  const palette = createPalette()
  const components = createComponents({ palette })
  const shadows = createShadows()
  const typography = createTypography()

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440
      }
    },
    components,
    palette,
    shadows,
    shape: {
      borderRadius: 4
    },
    typography
  })
}
