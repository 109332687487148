import { alpha } from '@mui/material/styles'

interface Color {
  lightest: string
  light: string
  main: string
  dark: string
  darkest: string
  contrastText: string
  alpha4?: string
  alpha8?: string
  alpha12?: string
  alpha30?: string
  alpha50?: string
}

const withAlphas = (color: Color): Color => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5)
  }
}

export const neutral: Record<string, string> = {
  50: '#E4EDFF',
  100: '#C2D4E9',
  200: '#A3B6CF',
  300: '#8298B6',
  400: '#6A83A3',
  500: '#516E90',
  600: '#44607F',
  700: '#354D69',
  800: '#263B53',
  900: '#14273B'
}

export const blue: Color = withAlphas({
  lightest: '#E6F7FF',
  light: '#40A9FF',
  main: '#096DD9',
  dark: '#003A8C',
  darkest: '#002766',
  contrastText: '#FFFFFF'
})

export const gray: Color = withAlphas({
  lightest: '#F5F5F5',
  light: '#D9D9D9',
  main: '#BFBFBF',
  dark: '#8C8C8C',
  darkest: '#595959',
  contrastText: '#FFFFFF'
})

export const success: Color = withAlphas({
  lightest: '#F6FFED',
  light: '#95DE64',
  main: '#52C41A',
  dark: '#237804',
  darkest: '#092B00',
  contrastText: '#FFFFFF'
})

export const info: Color = withAlphas({
  lightest: '#E6FFFB',
  light: '#5CDBD3',
  main: '#13C2C2',
  dark: '#006D75',
  darkest: '#002329',
  contrastText: '#FFFFFF'
})

export const warning: Color = withAlphas({
  lightest: '#FFFAEB',
  light: '#FFF4E5',
  main: '#ed6c02',
  dark: '#e65100',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF'
})

export const error: Color = withAlphas({
  lightest: '#FFF1F0',
  light: '#FF7875',
  main: '#F5222D',
  dark: '#A8071A',
  darkest: '#5C0011',
  contrastText: '#FFFFFF'
})

export const divider = '#E9ECFB'

export const icon = {
  main: '#FFFFFFBD',
  active: '#C2D4E9'
}
