import axios from 'axios'
import { useState } from 'react'
import { FileWithPath } from 'react-dropzone'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import CheckIcon from '@mui/icons-material/Check'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'

import CompanyAutocomplete from '@/components/CompanyAutocomplete'
import DropzoneUploadFileWithPages from '@/components/DropzoneUploadFileWithPages'
import ProcessingModal from '@/components/ProcessingModal'
import {
  statementClassifiedDeprecatedMappingCreateRequest,
  statementClassifiedMappingCreateRequest
} from '@/requests/classified'

import 'dayjs/locale/es'

const Classified = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [company, setCompanyUUID] = useState('')
  const [currency, setCurrency] = useState('')
  const [companyRUT, setCompanyRUT] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [pageTexts, setPageTexts] = useState<string[]>([])
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPath[]>([])

  const clearAcceptedFiles = () => {
    setAcceptedFiles([])
    setPageTexts([])
  }

  async function handleSubmit() {
    try {
      await handleFileUpload(acceptedFiles, pageTexts)
      clearAcceptedFiles()
    } catch (error) {
      console.error('Failed to upload file:', error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleFileUpload = async (files: File[], pages: string[]) => {
    if (!company || !currency) {
      toast.error('Por favor, selecciona la empresa y la moneda', {
        toastId: 'error-token',
        position: toast.POSITION.BOTTOM_RIGHT
      })
      throw new Error('Por favor, selecciona la empresa y la moneda')
    }

    try {
      let response
      // TODO: Remove this when the new classified view is ready
      const isDevelopment = true // import.meta.env.MODE === 'development'

      if (isDevelopment) {
        response = await statementClassifiedMappingCreateRequest(
          files,
          pages,
          currency,
          company
        )
        const statementUUID = response?.data?.uuid
        navigate(`/statements/${statementUUID}/classified`)
      } else {
        response = await statementClassifiedDeprecatedMappingCreateRequest(
          files,
          pages,
          currency,
          company
        )
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${companyRUT}.xlsx`)
        document.body.appendChild(link)
        link.click()

        // Clear the blob reference
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }

      if (response.status !== 201) {
        toast.error(
          `Hubo un problema al descargar el reporte. Estado: ${response.status}`,
          {
            toastId: 'error-token',
            position: toast.POSITION.BOTTOM_RIGHT
          }
        )
        return
      }
    } catch (error) {
      const errorMessage = axios.isAxiosError(error)
        ? error.message
        : 'Unknown error occurred'
      toast.error(`Error: ${errorMessage}`, {
        toastId: 'error-token',
        position: toast.POSITION.BOTTOM_RIGHT
      })
      throw new Error(errorMessage)
    }
  }

  const handleCompanySelect = ({
    companyUUID,
    companyRUT
  }: {
    companyUUID: string
    companyRUT: string
    companyName?: string
  }) => {
    if (!companyUUID) {
      return
    }
    setCompanyUUID(companyUUID)
    setCompanyRUT(companyRUT)
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Paper
        elevation={3}
        variant="elevation"
        style={{ padding: '16px', marginBottom: '16px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Información</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CompanyAutocomplete onCompanySelect={handleCompanySelect} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">Seleccionar moneda</MenuItem>
                  <MenuItem value="CLP">Pesos Chilenos</MenuItem>
                  <MenuItem value="MCLP">Miles de Pesos Chilenos</MenuItem>
                  <MenuItem value="USD">Dólares Estadounidenses</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={3} variant="elevation" style={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Subir archivo</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <DropzoneUploadFileWithPages
              acceptedFiles={acceptedFiles}
              setAcceptedFiles={setAcceptedFiles}
              pageTexts={pageTexts}
              setPageTexts={setPageTexts}
              acceptedFileTypes={{
                'application/pdf': ['.pdf'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  []
              }}
              maxFiles={10}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{
            margin: theme.spacing(1, 1, 1, 0),
            color: theme.palette.grey[700],
            borderColor: theme.palette.grey[700]
          }}
          type="button"
          variant="outlined"
          onClick={() => {
            clearAcceptedFiles()
            navigate('/statements')
          }}
        >
          Cancelar
        </Button>
        <Button
          sx={{
            margin: theme.spacing(1, 1, 1, 0)
          }}
          onClick={() => {
            setSubmitting(true)
            handleSubmit()
          }}
          variant="contained"
          color="primary"
          disabled={acceptedFiles.length === 0}
          startIcon={<CheckIcon />}
        >
          Crear reporte
        </Button>
      </Box>
      <ProcessingModal open={submitting} />
    </Box>
  )
}

export default Classified
