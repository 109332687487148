import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'

import SelectDate from '@/components/DatePicker'

interface FinalizeModalProps {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  handlePeriodChange: (e: string) => void
  date: string
  rut: string
  razonSocial: string
}

const FinalizeModal: React.FC<FinalizeModalProps> = ({
  open,
  onClose,
  onSubmit,
  handlePeriodChange,
  date,
  rut,
  razonSocial
}) => {
  const handleSubmit = () => {
    onSubmit()
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: 4, paddingY: 4 } }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        ¿Finalizar edición del Balance {rut} <br /> {razonSocial}?
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Typography sx={{ marginBottom: 2 }}>
          Por favor ingresa el período para finalizar la edición.
        </Typography>
        <SelectDate
          date={date}
          setDate={(e) => handlePeriodChange(e)}
          label="Periodo"
          disableDates={true}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={onClose} color="error" variant="text">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={date === '1900-01-01'}
        >
          Sí, finalizar edición
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FinalizeModal
