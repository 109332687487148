import { useCallback, useRef, useState } from 'react'

interface UsePopoverReturn {
  anchorRef: React.RefObject<HTMLElement>
  handleClose: () => void
  handleOpen: () => void
  handleToggle: () => void
  open: boolean
}

function usePopover(): UsePopoverReturn {
  const anchorRef = useRef<HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleToggle = useCallback(() => {
    setOpen((prevState) => !prevState)
  }, [])

  return {
    anchorRef,
    handleClose,
    handleOpen,
    handleToggle,
    open
  }
}

export default usePopover
