import { MouseEvent, useContext } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import { Theme, alpha, styled } from '@mui/material/styles'
import { SxProps } from '@mui/system'

import AuthContext, { AuthContextType } from '@/context/AuthContext'
import usePopover from '@/hooks/usePopover'

import { AccountPopover } from './account-popover'

const drawerWidth = 279

interface AppBarComponent {
  theme?: Theme
  open: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }: AppBarComponent) => ({
  transition: theme!.transitions.create(['margin', 'width'], {
    easing: theme!.transitions.easing.sharp,
    duration: theme!.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme!.transitions.create(['margin', 'width'], {
      easing: theme!.transitions.easing.easeOut,
      duration: theme!.transitions.duration.enteringScreen
    })
  })
}))

function stringToColor(string: string): string {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

interface AvatarProps {
  sx: SxProps<Theme>
  children: string
}

function stringAvatar(name: string): AvatarProps | null {
  if (!name) return null
  const nameParts = name.split(' ')
  return {
    sx: {
      bgcolor: stringToColor(name),
      cursor: 'pointer',
      height: 40,
      width: 40
    },
    children: nameParts.map((part) => part[0]).join('')
  }
}

interface HeaderProps {
  open: boolean
  handleSidebar: (event: MouseEvent<HTMLButtonElement>) => void
}

const Header = (props: HeaderProps) => {
  const { open, handleSidebar } = props
  const accountPopover = usePopover()
  const { user } = useContext(AuthContext) as AuthContextType

  return (
    <AppBar
      position="absolute"
      open={open}
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        color: (theme) => theme.palette.text.primary
      }}
    >
      <Toolbar
        sx={{
          justifyContent: open ? 'flex-end' : 'space-between'
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleSidebar}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' })
          }}
        >
          <MenuIcon />
        </IconButton>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Avatar
            onClick={accountPopover.handleOpen}
            ref={accountPopover.anchorRef as React.RefObject<HTMLDivElement>}
            sx={{
              cursor: 'pointer',
              height: 40,
              width: 40
            }}
            {...stringAvatar(user?.username as string)}
          />
        </Stack>
      </Toolbar>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </AppBar>
  )
}

export default Header
