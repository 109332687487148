import { ReactNode, useContext } from 'react'

import { Navigate } from 'react-router-dom'

import AuthContext, { AuthContextType } from '@/context/AuthContext'

interface PrivateRouteProps {
  children: ReactNode
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { isLoggedIn, isAuthenticated } = useContext(
    AuthContext
  ) as AuthContextType

  if (isLoggedIn && !isAuthenticated) {
    return <div>Loading...</div>
  }
  return isLoggedIn ? children : <Navigate to="/" />
}

export default PrivateRoute
