import React, { FormEvent, useCallback, useEffect, useState } from 'react'

import { isAxiosError } from 'axios'
import dayjs from 'dayjs'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import CheckIcon from '@mui/icons-material/Check'
import DownloadIcon from '@mui/icons-material/Download'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  MenuList,
  TextField,
  Typography
} from '@mui/material'
import Autocomplete, { AutocompleteValue } from '@mui/material/Autocomplete'
import GroupItems from '@mui/material/List'
import { useTheme } from '@mui/material/styles'

import NumericField from '@/components/DialogNumericTextField'
import GridEdit from '@/components/GridEdit'
import GroupHeader from '@/components/GroupHeader'
import {
  statementAccountClassesListRequest,
  statementItemListRequest,
  statementItemsCreateRequest,
  statementMappingGetRequest,
  statementMappingReportRequest,
  statementMappingUpdateRequest
} from '@/requests/accounts'
import { neutral } from '@/theme/colors'
import { CuentaType, requestCuentaType } from '@/types/Cuenta'
import { convertToCuentaType, setDisabledProperty } from '@/utils'

import FinalizeModal from './FinalizeModal'

import 'dayjs/locale/es'

type reponseCuentaType = {
  uuid: string
  index: number
  account: string
  user_class_code: string
  totals_debit: string
  totals_credit: string
  balances_debtor: string
  balances_creditor: string
  inventory_assets: string
  inventory_liabilities: string
  results_losses: string
  results_profits: string
  status: boolean
}

type CompanyData = {
  name?: string
  rut?: string
}

type OptionType = {
  value: string
  label: string
  parent: string
}

const BalanceDetails: React.FC = () => {
  const theme = useTheme()

  const [openFinalizeModal, setOpenFinalizeModal] = useState(false)
  const [tableData, setTableData] = useState<CuentaType[]>([])
  const [companyData, setCompanyData] = useState<CompanyData>({})
  const { uuid } = useParams<{ uuid: string }>()
  const [date, setDate] = useState('')
  const [confidence, setConfidence] = useState('')
  const [confidenceColor, setConfidenceColor] = useState('')
  const [statementValidated, setStatementValidated] = useState(false)
  const [accountClassesOptions, setAccountClassesOptions] = useState<
    OptionType[]
  >([])
  const [loadingFile, setLoadingFile] = useState(false)
  const [openRowCreate, setOpenRowCreate] = useState(false)
  const [rowCreationNewIndex, setRowCreationNewIndex] = useState<number | null>(
    null
  )
  const [rowCreatedIndex, setRowCreatedIndex] = useState<number | null>(null)
  const [rowCreationUserClassCode, setRowCreationUserClassCode] =
    useState<string>('001')

  /* Dialog create row */
  const handleOpenRowCreate = () => {
    setOpenRowCreate(true)
  }
  const handleCloseRowCreate = () => {
    setOpenRowCreate(false)
  }

  const handleRowSubmit = async (event: FormEvent<HTMLFormElement>) => {
    if (!uuid) return
    event.preventDefault()
    handleCloseRowCreate()
    setOpenRowCreate(false)

    const formData = new FormData(event.currentTarget)
    const newRow = {
      index: rowCreationNewIndex,
      account: formData.get('account') as string,
      totals_debit: 0,
      totals_credit: 0,
      balances_debtor: 0,
      balances_creditor: 0,
      inventory_assets: Number(formData.get('inventory_assets')),
      inventory_liabilities: Number(formData.get('inventory_liabilities')),
      results_losses: Number(formData.get('results_losses')),
      results_profits: Number(formData.get('results_profits')),
      user_class_code: rowCreationUserClassCode
    }
    // Send newRow to your API endpoint
    try {
      const response = await statementItemsCreateRequest(
        uuid,
        newRow as Partial<requestCuentaType> /* uuid created in backend */
      )
      if (response.status === 201) {
        toast.success('Fila creada correctamente.', {
          toastId: 'success-token'
        })
        setRowCreatedIndex(rowCreationNewIndex)
        forceRefreshDataTable()
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(
          `Hubo un problema al crear el item. Error: ${
            error.response?.data?.message as string
          }`,
          {
            toastId: 'error-token'
          }
        )
        const fields = error.response?.data?.extra.fields || {}

        for (const [field, messages] of Object.entries(fields)) {
          for (const message of messages as string) {
            toast.error(`Campo: ${field} - ${message}`)
          }
        }
      }
      console.error(error)
    }
  }

  function handleChangeDate(e: string) {
    if (!uuid) return
    const status = statementValidated ? 'AP' : 'PA'
    const newDate = dayjs(e).format('YYYY-MM-DD')
    setDate(newDate)
    statementMappingUpdateRequest(uuid, newDate, status)
  }

  function determineConfidenceLevel(cellsConfidence: number) {
    if (cellsConfidence < 65) {
      setConfidenceColor('#D44C47') // Red Color
      return 'BAJA'
    } else if (cellsConfidence >= 65 && cellsConfidence < 85) {
      setConfidenceColor('#CB912F') // Yellow Color
      return 'MEDIA'
    } else {
      setConfidenceColor('#448361') // Green Color
      return 'ALTA'
    }
  }

  useEffect(() => {
    const fecthStatementAssetClasses = async () => {
      try {
        const response = await statementAccountClassesListRequest()
        // convert response.data to accountClassesOptions
        const accountClassesOptions = response?.data?.map(
          (item: { code: string; subcategory: string; category: string }) => {
            return {
              value: item.code,
              label: item.subcategory,
              parent: item.category
            }
          }
        )
        setAccountClassesOptions(accountClassesOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fecthStatementAssetClasses()
  }, [])

  const fetchStatementMapping = useCallback(async () => {
    try {
      setTableData([])
      let fileData: CuentaType[] = [
        {
          uuid: '',
          index: 0,
          cuenta: '',
          user_class_code: '',
          debitos: 0.0,
          creditos: 0.0,
          deudor: 0.0,
          acreedor: 0.0,
          activo: 0.0,
          pasivo: 0.0,
          perdida: 0.0,
          ganancia: 0.0
        }
      ]
      // get the date of the file
      const responseStatement = await statementMappingGetRequest(uuid as string)
      setDate(responseStatement?.data?.date)
      setConfidence(
        determineConfidenceLevel(responseStatement?.data?.cells_confidence)
      )
      // get the items
      const responseStatementItems = await statementItemListRequest(
        uuid as string
      )
      fileData = responseStatementItems?.data?.map(
        (data: reponseCuentaType, index: number, array: CuentaType[]) => {
          const cuentaTypeData = convertToCuentaType(data)
          return setDisabledProperty(cuentaTypeData, index, array)
        }
      )
      setStatementValidated(responseStatement?.data?.status === 'AP')
      setDate(responseStatement?.data?.date)
      setCompanyData((currentData) => ({
        ...currentData,
        rut: responseStatement?.data?.company_rut,
        name: responseStatement?.data?.company_name
      }))
      setTableData(fileData)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }, [uuid])

  useEffect(() => {
    fetchStatementMapping()
  }, [uuid, fetchStatementMapping])

  const downloadReport = async (uuid: string) => {
    try {
      setLoadingFile(true)
      const response = await statementMappingReportRequest(uuid)

      // Check if the response has an error status
      if (response.status !== 200) {
        toast.error(
          `Hubo un problema al descargar el reporte. Estado: ${response.status}`,
          {
            toastId: 'error-token',
            position: toast.POSITION.BOTTOM_RIGHT
          }
        )
        return
      }

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const companyRut = companyData.rut || '00000000-0'
      link.setAttribute('download', `${companyRut}.xlsx`)
      document.body.appendChild(link)
      link.click()

      // Clear the blob reference
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      toast.error(
        `Hubo un error descargando el reporte: ${(error as Error).message}`,
        {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        }
      )
    } finally {
      setLoadingFile(false)
    }
  }

  const forceRefreshDataTable = async () => {
    await fetchStatementMapping()
  }

  const sendValidationReport = async (uuid: string, status: string) => {
    if (date === '1900-01-01') {
      toast.error('Debe seleccionar una fecha para el periodo válida', {
        toastId: 'error-token',
        position: toast.POSITION.BOTTOM_RIGHT
      })
      return
    }

    try {
      const response = await statementMappingUpdateRequest(uuid, status, date)

      // Check if the response has an error status
      if (response.status !== 201) {
        toast.error(
          `Hubo un problema al validar el balance. Estado: ${response.status}`,
          {
            toastId: 'error-token',
            position: toast.POSITION.BOTTOM_RIGHT
          }
        )
        return
      }
      setStatementValidated(response.data.status === 'AP')
    } catch (error) {
      toast.error(
        `Hubo un error validando el balance: ${(error as Error).message}`,
        {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        }
      )
    }
  }

  const handleOpenFinalizeModal = () => {
    setOpenFinalizeModal(true)
  }

  const handleCloseFinalizeModal = () => {
    setOpenFinalizeModal(false)
  }

  const handleFinalizeSubmit = () => {
    if (uuid) {
      sendValidationReport(uuid, 'AP')
      handleCloseFinalizeModal()
    }
  }

  return (
    <Grid container>
      <Grid item>
        <Link to="/statements">
          <Button variant="text" sx={{ color: '#010B1B99', px: 0 }}>
            <ArrowBackIcon /> Volver a balances
          </Button>
        </Link>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        sx={{ mb: 2, mt: 2 }}
      >
        <Grid item>
          <Grid>
            <Typography variant="h5" sx={{ mr: 2, whiteSpace: 'nowrap' }}>
              {companyData.rut}
            </Typography>
          </Grid>
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid item sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <AccountCircleOutlinedIcon sx={{ color: 'gray', mr: 1 }} />
              <Typography
                variant="h6"
                fontWeight={400}
                fontSize="1rem"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {companyData.name}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 2,
                minWidth: 140,
                maxWidth: 180
              }}
            >
              <CalendarTodayOutlinedIcon sx={{ color: 'gray', mr: 1 }} />
              {dayjs(date).format('DD/MM/YYYY')}
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      bgcolor: confidenceColor,
                      display: 'inline-block',
                      mr: 1
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    Confianza OCR {confidence.toLowerCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {statementValidated ? (
            <>
              <Button
                variant="outlined"
                onClick={() => uuid && sendValidationReport(uuid, 'PA')}
                startIcon={<EditOutlinedIcon />}
                sx={{
                  mr: 2,
                  margin: theme.spacing(1, 1, 1, 0),
                  color: theme.palette.grey[700],
                  borderColor: theme.palette.grey[700]
                }}
              >
                Volver a editar
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={loadingFile}
                onClick={() => uuid && downloadReport(uuid)}
                startIcon={<DownloadIcon />}
              >
                {loadingFile ? <CircularProgress size={24} /> : 'Descargar'}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenFinalizeModal()}
              startIcon={<CheckIcon />}
            >
              Finalizar edición
            </Button>
          )}
        </Grid>
      </Grid>
      {tableData.length === 0 && <LinearProgress />}
      {tableData.length > 0 && (
        <GridEdit
          key={uuid as string}
          uuid={uuid as string}
          tableData={tableData}
          setTableData={setTableData}
          accountClassesOptions={accountClassesOptions}
          handleOpenRowCreate={handleOpenRowCreate}
          setRowCreationNewIndex={setRowCreationNewIndex}
          newRowIndex={rowCreatedIndex}
          statementValidated={statementValidated}
        />
      )}
      <FinalizeModal
        open={openFinalizeModal}
        onClose={handleCloseFinalizeModal}
        onSubmit={handleFinalizeSubmit}
        handlePeriodChange={handleChangeDate}
        date={date}
        rut={companyData.rut as string}
        razonSocial={companyData.name as string}
      />
      <Dialog open={openRowCreate} onClose={handleCloseRowCreate}>
        <DialogTitle>Agregando una nueva fila</DialogTitle>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '90%' },
            mb: 2
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleRowSubmit}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  La fila que se agregará se ubicará en el índice:{' '}
                  <b>{rowCreationNewIndex}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="account"
                  label="Cuenta"
                  defaultValue=""
                  required
                  fullWidth
                  variant="outlined"
                  placeholder="Ingrese el nombre de la cuenta"
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  defaultValue={
                    accountClassesOptions.find(
                      (option) => option.value === '001'
                    ) || null
                  }
                  onChange={(
                    _: React.SyntheticEvent,
                    newValue: AutocompleteValue<
                      OptionType,
                      false,
                      false,
                      false
                    > | null
                  ) => {
                    if (!newValue) return
                    setRowCreationUserClassCode(newValue.value)
                  }}
                  sx={{
                    '& + .MuiList-root': {
                      height: 'auto'
                    },
                    '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                      color: 'white',
                      backgroundColor: neutral[500]
                    },
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                      {
                        color: 'white',
                        backgroundColor: neutral[700]
                      },
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true'].Mui-focused":
                      {
                        color: 'white',
                        backgroundColor: neutral[600]
                      },
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='false'].Mui-focused":
                      {
                        color: 'white',
                        backgroundColor: neutral[300]
                      }
                  }}
                  groupBy={(option) => option.parent}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value &&
                    option.parent === value.parent
                  }
                  options={accountClassesOptions}
                  getOptionLabel={(option: OptionType) => option.label}
                  renderInput={(params) => (
                    <TextField
                      label="Cuenta Bci"
                      variant="outlined"
                      name={`dialog-inputComplete`}
                      {...params}
                    />
                  )}
                  renderGroup={(params) => {
                    return (
                      <MenuList key={params.key} sx={{ p: 0 }}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems sx={{ p: 0 }}>{params.children}</GroupItems>
                      </MenuList>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <NumericField name="inventory_assets" label="Activos" />
              </Grid>
              <Grid item xs={6}>
                <NumericField name="inventory_liabilities" label="Pasivos" />
              </Grid>
              <Grid item xs={6}>
                <NumericField name="results_losses" label="Perdidas" />
              </Grid>
              <Grid item xs={6}>
                <NumericField name="results_profits" label="Ganancia" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ pr: 5 }}>
            <Button
              onClick={handleCloseRowCreate}
              color="error"
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Agregar
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Grid>
  )
}

export default BalanceDetails
