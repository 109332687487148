import { FC, useEffect, useRef } from 'react'

import Select, { CSSObjectWithLabel, SelectInstance } from 'react-select'

import { neutral } from '@/theme/colors'
import { OptionType } from '@/types'

interface DIProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCellChanged: (...args: any[]) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: Record<string, any>
}

const DropdownInput: FC<DIProps> = ({ onCellChanged, cell }) => {
  const selectRef = useRef<SelectInstance<OptionType>>(null)

  useEffect(() => {
    if (cell.isOpen && selectRef.current) {
      selectRef.current.focus()
    }
  }, [cell.isOpen, cell.inputValue])

  return (
    <div style={{ width: '100%' }} onPointerDown={(e) => e.stopPropagation()}>
      <Select
        ref={selectRef}
        isSearchable={true}
        placeholder="Buscar cuenta..."
        {...(cell.isOpen !== undefined && { menuIsOpen: cell.isOpen })}
        onMenuOpen={() => onCellChanged({ ...cell, isOpen: true })}
        onChange={(e) =>
          onCellChanged({
            ...cell,
            selectedValue: (e as unknown as OptionType).value || '',
            isOpen: false,
            inputValue: (e as unknown as OptionType).label || ''
          })
        }
        menuPlacement="auto"
        blurInputOnSelect={true}
        defaultValue={undefined}
        value={undefined}
        isDisabled={cell.isDisabled}
        options={cell.values}
        getOptionLabel={(option: OptionType) =>
          `${option.parent} - ${option.label}`
        }
        onKeyDown={(e) => {
          e.stopPropagation()

          if (e.key === 'Escape') {
            if (selectRef.current) selectRef.current.blur()
            return onCellChanged({
              ...cell,
              isOpen: false,
              inputValue: undefined
            })
          }
        }}
        styles={{
          container: (provided) =>
            ({
              ...provided,
              width: '100%',
              height: '100%'
            } as CSSObjectWithLabel),
          control: (provided) =>
            ({
              ...provided,
              border: 'none',
              // borderColor: 'transparent',
              minHeight: '25px',
              // background: 'transparent',
              boxShadow: 'none'
            } as CSSObjectWithLabel),
          menu: (base) =>
            ({
              ...base,
              width: 'max-content',
              minWidth: '100%'
            } as CSSObjectWithLabel),
          indicatorsContainer: (provided) =>
            ({
              ...provided,
              paddingTop: '0px'
            } as CSSObjectWithLabel),
          dropdownIndicator: (provided) =>
            ({
              ...provided,
              padding: '0px 4px'
            } as CSSObjectWithLabel),
          singleValue: (provided) =>
            ({
              ...provided,
              color: 'inherit'
            } as CSSObjectWithLabel),
          indicatorSeparator: (provided) =>
            ({
              ...provided,
              marginTop: '4px',
              marginBottom: '4px'
            } as CSSObjectWithLabel),
          input: (provided) =>
            ({
              ...provided,
              padding: 0
            } as CSSObjectWithLabel),
          valueContainer: (provided) =>
            ({
              ...provided,
              padding: '0 8px'
            } as CSSObjectWithLabel),
          option: (provided, state) =>
            ({
              ...provided,
              color: 'white',
              whiteSpace: 'nowrap',
              padding: '2px 12px',
              backgroundColor: state.isSelected
                ? state.isFocused
                  ? neutral[600]
                  : neutral[700]
                : state.isFocused
                ? neutral[300]
                : neutral[500]
            } as CSSObjectWithLabel)
        }}
      />
    </div>
  )
}

export default DropdownInput
