import { useState } from 'react'

import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, TextField, Typography } from '@mui/material'

import bciLogo from '@/assets/bci_logo.png'
import CenteredBox from '@/components/CenteredBox'
import { resetPasswordConfirmRequest } from '@/requests/auth'

const LoginPasswordResetPage = () => {
  const { uid, token } = useParams()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const navigate = useNavigate()

  const handleResetPassword = async () => {
    if (!uid || !token) {
      toast.error('Enlace de restablecimiento inválido')
      return
    }
    if (password !== confirmPassword) {
      toast.error('¡Las contraseñas no coinciden!')
      return
    }
    try {
      const response = await resetPasswordConfirmRequest(
        uid,
        token,
        password,
        confirmPassword
      )
      if (response.status !== 200) {
        toast.error('¡Error al restablecer la contraseña!')
        return
      }
      // Perform reset password logic here
      navigate('/')
      toast.success('¡Restablecimiento de contraseña exitoso!')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle AxiosError
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data?.extra?.fields?.new_password2
            ? error.response.data.extra.fields.new_password2[0]
            : error.response.data?.message
          toast.error(errorMessage)
        } else {
          console.error(error.response)
        }
      } else {
        // Handle unexpected error
        console.error(error)
      }
    }
  }
  return (
    <CenteredBox>
      <Box
        sx={{
          minWidth: '35%',
          paddingLeft: 4,
          paddingRight: 4
        }}
      >
        <Link to="/">
          <Button variant="text" color="secondary">
            <ArrowBackIcon /> Volver al inicio
          </Button>
        </Link>
      </Box>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault()
          handleResetPassword()
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '35%',
          gap: 2, // space between TextField and Button
          backgroundColor: 'white',
          padding: 4, // 4 * theme.spacing unit (usually 8px) = 32px
          borderRadius: 2,
          boxShadow:
            '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)'
        }}
      >
        <img src={bciLogo} alt="bci logo" style={{ maxWidth: '64px' }} />
        <Typography variant="h6" align="left">
          Restablecer contraseña
        </Typography>
        <TextField
          label="Contraseña"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="new-password"
        />
        <TextField
          label="Confirmar contraseña"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          autoComplete="new-password"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleResetPassword}
        >
          Restablecer
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default LoginPasswordResetPage
