import { client as axiosClient } from './clients'

const request = axiosClient({
  timeout: 60 * 2 * 1000 // Waiting 2 min
})

export function userStatsRequest(filterOption = 'everything') {
  return request.get('/api/reports/user-statement-stats/', {
    params: {
      filter_option: filterOption
    }
  })
}
