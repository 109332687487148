import { memo, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'

import Header from '@/components/Header'
import SideBar from '@/components/Sidebar'

interface BoxMainProps {
  open: boolean
}

const BoxMain = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open'
})<BoxMainProps>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const MemoizedAppContentRouter = memo(Outlet)

const drawerWidth = 279

const MainLayout = () => {
  const theme = useTheme()
  const isLgScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const [open, setOpen] = useState<boolean>(isLgScreen)

  const handleSidebar = () => {
    setOpen((status) => !status)
  }

  return (
    <Box data-testid="layout" sx={{ display: 'flex' }}>
      <Header open={open} handleSidebar={handleSidebar} />
      <SideBar open={open} handleSidebar={handleSidebar} />
      <BoxMain
        component="main"
        data-testid="main"
        open={open}
        sx={{
          backgroundColor: 'white',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: '71px' // header height
        }}
      >
        <Container
          data-testid={'dashboardMenu'}
          maxWidth={false}
          sx={{ mt: 1, mb: 0 }}
          disableGutters={true}
        >
          <MemoizedAppContentRouter />
        </Container>
      </BoxMain>
    </Box>
  )
}

export default MainLayout
