import { CompanyType } from '@/types/Company'

import { client as axiosClient } from './clients'

const request = axiosClient({
  timeout: 60 * 2 * 1000 // Waiting 2 min
})

export async function companyListRequest(rut: string = '') {
  return request.get('/api/companies/', {
    params: { rut }
  })
}

export async function companyDetailRequest(uuid: string) {
  return request.get(`/api/companies/${uuid}/`)
}

export async function companyCreateRequest(data: CompanyType) {
  return request.post('/api/companies/create/', data)
}

export async function companyUpdateRequest(uuid: string, data: CompanyType) {
  return request.post(`/api/companies/${uuid}/update/`, data)
}

export async function companyNameGetSIIRequest(rut: string) {
  return request.get(`/api/companies/sii-name/${rut}/`)
}
