import { isEmpty } from 'lodash'
import moment from 'moment'

import { CuentaType, requestCuentaType } from '@/types/Cuenta'

export const TOTAL_ACCOUNT_CLASS = '002' // '002' is the code for 'total' account class

export function convertToNumber(value: string): number {
  const number = Number(value)
  return isNaN(number) ? 0 : number
}

export function convertToCuentaType(data: {
  uuid: string
  index: number
  account: string
  user_class_code: string
  totals_debit: string
  totals_credit: string
  balances_debtor: string
  balances_creditor: string
  inventory_assets: string
  inventory_liabilities: string
  results_losses: string
  results_profits: string
}): CuentaType {
  return {
    uuid: data.uuid,
    index: data.index,
    cuenta: data.account,
    user_class_code: data.user_class_code,
    debitos: convertToNumber(data.totals_debit),
    creditos: convertToNumber(data.totals_credit),
    deudor: convertToNumber(data.balances_debtor),
    acreedor: convertToNumber(data.balances_creditor),
    activo: convertToNumber(data.inventory_assets),
    pasivo: convertToNumber(data.inventory_liabilities),
    perdida: convertToNumber(data.results_losses),
    ganancia: convertToNumber(data.results_profits)
  } as CuentaType
}

export function convertAttributeToExternal(
  field: Partial<CuentaType>,
  attribute: keyof CuentaType
): Partial<requestCuentaType> {
  const attributeMapping: Record<string, string> = {
    cuenta: 'account',
    user_class_code: 'user_class_code',
    debitos: 'totals_debit',
    creditos: 'totals_credit',
    deudor: 'balances_debtor',
    acreedor: 'balances_creditor',
    activo: 'inventory_assets',
    pasivo: 'inventory_liabilities',
    perdida: 'results_losses',
    ganancia: 'results_profits'
  }

  const newAttribute = attributeMapping[attribute]
  if (newAttribute && field[attribute] !== undefined) {
    return {
      uuid: field?.uuid,
      [newAttribute]: field[attribute]
    }
  }

  return {}
}

export function convertCuentaToString(data: CuentaType): requestCuentaType {
  return {
    uuid: data.uuid,
    index: data.index,
    account: data.cuenta,
    user_class_code: data.user_class_code,
    totals_debit: data.debitos,
    totals_credit: data.creditos,
    balances_debtor: data.deudor,
    balances_creditor: data.acreedor,
    inventory_assets: data.activo,
    inventory_liabilities: data.pasivo,
    results_losses: data.perdida,
    results_profits: data.ganancia
  } as requestCuentaType
}

export function setDisabledProperty(
  cuenta: CuentaType,
  index: number,
  array: CuentaType[]
): CuentaType {
  const isLastElement = index === array.length - 1
  const isSubcategoryTotal =
    !!cuenta.user_class_code &&
    cuenta.user_class_code.toLowerCase() === TOTAL_ACCOUNT_CLASS

  // Case 1: The account is disabled if all the keys 'activo', 'pasivo', 'perdida', 'ganancia' have a value of 0.0
  // Case 2: The account is also disabled if it's a 'total' subcategory but not the last element in the array
  cuenta.disabled =
    ['activo', 'pasivo', 'perdida', 'ganancia'].every(
      (key) => cuenta[key as keyof CuentaType] === 0.0
    ) ||
    (isSubcategoryTotal && !isLastElement)
  return cuenta
}

export enum RutFormat {
  DOTS,
  DASH,
  DOTS_DASH
}
const rutLikePattern = (): RegExp => /^(\d{0,2})\.?(\d{3})\.?(\d{3})-?(\d|k)$/gi
const suspiciousRutPattern = (): RegExp =>
  /^(\d)\1?\.?(\1{3})\.?(\1{3})-?(\d|k)?$/gi
const cleanRut = (rut: string): string =>
  isRutLike(rut) ? rut.replace(/[^0-9k]/gi, '') : ''
const isRutLike = (rut: string): boolean => rutLikePattern().test(rut)
const isSuspiciousRut = (rut: string): boolean =>
  suspiciousRutPattern().test(rut)
const getRutVerifier = (rut: string): string => cleanRut(rut).slice(-1)
const getRutDigits = (rut: string): string => cleanRut(rut).slice(0, -1)

const calculateRutVerifier = (digits: string): string => {
  let sum = 0
  let mul = 2

  let i = digits.length
  while (i--) {
    sum = sum + parseInt(digits.charAt(i)) * mul
    if (mul % 7 === 0) {
      mul = 2
    } else {
      mul++
    }
  }

  const res = sum % 11

  if (res === 0) {
    return '0'
  } else if (res === 1) {
    return 'k'
  }

  return `${11 - res}`
}

export const formatRut = (
  rut?: string,
  format = RutFormat.DOTS_DASH
): string => {
  if (rut === null || rut === undefined) return ''
  if (typeof rut !== 'string')
    throw new TypeError('RUT needs to be a string or undefined')
  const unformattedRut = !rut
    ? ''
    : rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
  if (!isRutLike(unformattedRut)) return unformattedRut

  switch (format) {
    case RutFormat.DOTS:
      return unformattedRut.replace(
        rutLikePattern(),
        (...m) => `${m[1] ? `${m[1]}.` : ''}${m[2]}.${m[3]}${m[4]}`
      )

    case RutFormat.DASH:
      return unformattedRut.replace(rutLikePattern(), '$1$2$3-$4')

    case RutFormat.DOTS_DASH:
      return unformattedRut.replace(
        rutLikePattern(),
        (...m) => `${m[1] ? `${m[1]}.` : ''}${m[2]}.${m[3]}-${m[4]}`
      )

    default:
      return unformattedRut.replace(rutLikePattern(), '$1$2$3$4')
  }
}

export function rutValid(value?: string): boolean {
  const unformatted = !value
    ? ''
    : value.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()

  if (/^0+/.test(unformatted) || !isRutLike(unformatted)) {
    return false
  }

  let remainer = parseInt(unformatted.slice(0, -1), 10)
  let module = 1
  let counter = 0

  while (remainer > 0) {
    module = (module + (remainer % 10) * (9 - (counter++ % 6))) % 11
    remainer = Math.floor(remainer / 10)
  }

  const verifier = module > 0 ? '' + (module - 1) : 'K'

  return verifier === unformatted.slice(-1)
}

export const validateRut = (rut: string, noSuspicious = true): boolean => {
  if (!isRutLike(rut)) return false
  if (noSuspicious && isSuspiciousRut(rut)) return false
  return (
    getRutVerifier(rut).toLowerCase() ===
    calculateRutVerifier(getRutDigits(rut))
  )
}

export const Group = {
  Jefatura: 'Jefatura',
  Ejecutivo: 'Ejecutivo'
}

const checkGroups = (groups: string[], neededGroups: string[]): boolean => {
  if (!isEmpty(groups)) {
    return groups.some((r) => neededGroups.includes(r))
  }
  return false
}

export const isGroupCanView = (
  groups: string[],
  neededGroups: string[]
): boolean => {
  return checkGroups(groups, neededGroups)
}

export function secondsToHms(d: number | null) {
  if (d === null) return 'N/A'
  const duration = moment.duration(d, 'seconds')
  return `${Math.floor(
    duration.asHours()
  )}h ${duration.minutes()}m ${duration.seconds()}s`
}

// forked from: https://stackoverflow.com/questions/58764552/validate-page-ranges-for-printing-regular-expression
const isNumeric = (input: string): boolean => !isNaN(Number(input))
const isOrdered = (start: string, end: string): boolean =>
  parseInt(start, 10) < parseInt(end, 10)
const isRangeValid = (range: string[]): boolean =>
  range.length === 2 && range.every(isNumeric) && isOrdered(range[0], range[1])
const isSingleValid = (single: string[]): boolean =>
  single.length === 1 && isNumeric(single[0])

export function validatePagesInput(input: string): boolean {
  const inputs = input.split(',').map((x) => x.trim())

  for (const x of inputs) {
    if (!x) return false
    const pages = x.split('-')
    if (!isSingleValid(pages) && !isRangeValid(pages)) return false
  }

  return true
}
