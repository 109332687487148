import { RouterProvider } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'

import { ThemeProvider } from '@mui/material/styles'

import RequestInterceptor from '@/components/RequestInterceptor'
import AuthProvider from '@/context/AuthProvider'
import router from '@/router'
import { createTheme } from '@/theme'
import CssBaseline from '@mui/material/CssBaseline'
import 'react-toastify/dist/ReactToastify.min.css'

// App.js

const App = () => {
  const theme = createTheme()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <RequestInterceptor>
          <RouterProvider router={router} />
        </RequestInterceptor>
      </AuthProvider>
      <ToastContainer position="bottom-center" autoClose={10000} />
    </ThemeProvider>
  )
}

export default App
