import { ReactNode, useContext, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Collapse from '@mui/material/Collapse'
import Stack from '@mui/material/Stack'

import AuthContext, { AuthContextType } from '@/context/AuthContext'
import { Route } from '@/router/private'
import { neutral } from '@/theme/colors'

interface WrapperButtonLinkProps {
  subMenu?: Route[] | undefined
  path: string
  children: ReactNode
}

const WrapperButtonLink = ({
  subMenu,
  path,
  children
}: WrapperButtonLinkProps) => {
  return subMenu === undefined ? (
    <Link
      to={{ pathname: path }}
      style={{
        color: 'inherit',
        textDecoration: 'inherit'
      }}
    >
      {children}
    </Link>
  ) : (
    <Box>{children}</Box>
  )
}

interface MenuItemProps {
  item: Route
  index: string
  onClick: (index: string) => void
  hasAccess: (groups: string[]) => boolean
  ident?: number
}

const MenuItem = ({
  item,
  index,
  onClick,
  hasAccess,
  ident = 0
}: MenuItemProps) => {
  const location = useLocation()
  const theme = useTheme()
  const isActive = location.pathname.startsWith(item.path)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  // if menu item is hidden, return null
  if (item.hiddenMenu) return null
  if (item?.groups && !hasAccess(item.groups)) {
    return null
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
    onClick(index)
  }

  return (
    <Box key={index}>
      <WrapperButtonLink subMenu={item.children} path={item.path}>
        <ButtonBase
          onClick={handleToggle}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            pr: '16px',
            py: '6px',
            textAlign: 'left',
            width: '100%',
            '&:hover': {
              backgroundColor: neutral[800]
            },
            pl: `${ident}px`
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              justifyContent: 'center',
              mr: 2,
              color: '#FFFFFFBD',
              ...(isActive && {
                color: theme.palette.primary.main
              })
            }}
          >
            {item.icon}
          </Box>
          <Box
            component="span"
            sx={{
              color: neutral[400],
              flexGrow: 1,
              fontFamily: theme.typography.fontFamily,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: '24px',
              whiteSpace: 'pre-wrap',
              ...(isActive && {
                color: 'common.white'
              }),
              ...(!isActive && {
                color: '#FFFFFFBD'
              })
            }}
          >
            {item.name}
          </Box>
          {item.children && isOpen ? (
            <ExpandLess />
          ) : item.children ? (
            <ExpandMore />
          ) : null}
        </ButtonBase>
        {item.children ? (
          <Collapse
            in={isOpen}
            timeout="auto"
            sx={{ marginBottom: '10px' }}
            unmountOnExit
          >
            <Stack
              component="ul"
              spacing={0.5}
              sx={{ listStyle: 'none', p: 0, m: 0 }}
            >
              {item.children.map((childItem, childIndex) => (
                <MenuItem
                  key={childIndex}
                  item={childItem}
                  index={`${index}-${childIndex}`}
                  onClick={onClick}
                  hasAccess={hasAccess}
                  ident={ident + 16}
                />
              ))}
            </Stack>
          </Collapse>
        ) : null}
      </WrapperButtonLink>
    </Box>
  )
}

interface SideBarMenuProps {
  routes: Route[]
}

export const SideBarMenu = ({ routes }: SideBarMenuProps) => {
  const { groups } = useContext(AuthContext) as AuthContextType
  const [open, setOpen] = useState<Record<string, boolean>>({})

  const hasAccess = (neededGroups: string[]) => {
    return neededGroups.some((group) => groups.includes(group))
  }

  const handleClick = (currentIndex: string) => {
    const newOpen = { ...open }
    newOpen[currentIndex] = !open[currentIndex]

    // Close children indexes
    for (const key in newOpen) {
      if (key.startsWith(`${currentIndex}-`)) {
        delete newOpen[key]
      }
    }

    setOpen(newOpen)
  }

  return (
    <Box>
      {routes.map((route, index) => (
        <MenuItem
          key={index}
          item={route}
          index={index.toString()}
          onClick={handleClick}
          hasAccess={hasAccess}
          ident={16}
        />
      ))}
    </Box>
  )
}
