import { clientUploadFile } from './clients'

export async function statementIFRSMappingCreateRequest(
  file: File,
  currency: string,
  company: string,
  companyType: string,
  pages: string,
  datePeriodCurrent: string,
  datePeriodPrevious: string
) {
  return clientUploadFile(
    '/api/ifrs/create/',
    file,
    {
      currency,
      company_uuid: company,
      company_type: companyType,
      pages,
      date_period_current: datePeriodCurrent,
      date_period_previous: datePeriodPrevious
    },
    'blob'
  )
}
