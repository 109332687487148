import { common } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'
import { PaletteOptions } from '@mui/material/styles/createPalette'

import {
  blue,
  divider,
  error,
  icon,
  info,
  neutral,
  success,
  warning
} from '@/theme/colors'

export interface CustomPalette extends PaletteOptions {
  icon: {
    main: string
    active: string
  }
  neutral: Record<string, string>
}

export function createPalette(): CustomPalette {
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12)
    },
    background: {
      default: common.white,
      paper: common.white
    },
    divider,
    error,
    info,
    mode: 'light',
    neutral,
    primary: blue,
    success,
    icon,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38)
    },
    warning
  }
}
