import * as React from 'react'

// NOTE: all modules imported below may be imported from '@silevis/reactgrid'
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  isAlphaNumericKey,
  keyCodes
} from '@silevis/reactgrid'

import DropdownInput from './DropdownInput'

export type OptionType = {
  label: string
  value: string
  isDisabled?: boolean
}

export interface ISelectCell extends Cell {
  type: 'select'
  selectedValue?: string
  values: OptionType[]
  isDisabled?: boolean
  isOpen?: boolean
  inputValue?: string
}

export class SelectCellTemplate implements CellTemplate<ISelectCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<ISelectCell>
  ): Compatible<ISelectCell> {
    // if (uncertainCell.type === 'select') {
    //   console.log(uncertainCell)
    // }
    let selectedValue: string | undefined

    try {
      selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'string')
    } catch {
      selectedValue = undefined
    }

    const values = getCellProperty(uncertainCell, 'values', 'object')
    const value = selectedValue ? parseFloat(selectedValue) : NaN

    let isDisabled = true
    try {
      isDisabled = getCellProperty(uncertainCell, 'isDisabled', 'boolean')
    } catch {
      isDisabled = false
    }

    let inputValue: string | undefined
    try {
      inputValue = getCellProperty(uncertainCell, 'inputValue', 'string')
    } catch {
      inputValue = undefined
    }

    let isOpen: boolean
    try {
      isOpen = getCellProperty(uncertainCell, 'isOpen', 'boolean')
    } catch {
      isOpen = true
    }

    const text = selectedValue || ''

    return {
      ...uncertainCell,
      selectedValue,
      text,
      value,
      values,
      isDisabled,
      isOpen,
      inputValue
    }
  }

  update(
    cell: Compatible<ISelectCell>,
    cellToMerge: UncertainCompatible<ISelectCell>
  ): Compatible<ISelectCell> {
    // I use the text property as a selectedValue property because behaviors don't know about the selectedValue property
    // and instead throw an error when we try to access it.
    // Before merging, we also need to check if the incoming value is in the target values array, otherwise we set it to undefined.
    const selectedValueFromText = cell.values.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (val: any) => val.value === cellToMerge.text
    )
      ? cellToMerge.text
      : undefined

    return this.getCompatibleCell({
      ...cell,
      selectedValue: selectedValueFromText,
      isOpen: cellToMerge.isOpen,
      inputValue: cellToMerge.inputValue
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getClassName(cell: Compatible<ISelectCell>, _isInEditMode: boolean): string {
    const isOpen = cell.isOpen ? 'open' : 'closed'
    return `${cell.className ? cell.className : ''}${isOpen}`
  }

  handleKeyDown(
    cell: Compatible<ISelectCell>,
    keyCode: number,
    ctrl: boolean,
    _shift: boolean,
    alt: boolean,
    key: string
  ): { cell: Compatible<ISelectCell>; enableEditMode: boolean } {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
      return { cell, enableEditMode: true }

    // copying code and not text to clipboard
    if (ctrl && key === 'c') {
      const selectedValue = cell.values.find(
        (val: OptionType) => val.value === cell.selectedValue
      )
      if (selectedValue) {
        // Store the currently focused element
        const focusedElement = document.activeElement as HTMLElement
        // do the copying workaround for mac users and windows also works.
        // this will create a temporary textare with the text to copy
        const textarea = document.createElement('textarea')
        textarea.textContent = selectedValue.value
        textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea)
        textarea.select()
        try {
          document.execCommand('copy') // Security exception may be thrown by some browsers.
          // Restore the focus
          if (focusedElement) focusedElement.focus()
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex)
        } finally {
          document.body.removeChild(textarea)
        }
      }
    }
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
    }
  }

  handleCompositionEnd(
    cell: Compatible<ISelectCell>,
    eventData: string
  ): { cell: Compatible<ISelectCell>; enableEditMode: boolean } {
    return {
      cell: { ...cell, inputValue: eventData, isOpen: false },
      enableEditMode: false
    }
  }

  render(
    cell: Compatible<ISelectCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ISelectCell>, commit: boolean) => void
  ): React.ReactNode {
    if (!isInEditMode) {
      return (
        <div className={cell.className}>
          <span>{cell.inputValue}</span>
        </div>
      )
    }
    return (
      <DropdownInput
        onCellChanged={(cell) =>
          onCellChanged(this.getCompatibleCell(cell), true)
        }
        cell={cell}
      />
    )
  }
}
