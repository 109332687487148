import { CuentaType, requestCuentaType } from '@/types/Cuenta'
import {
  client as axiosClient,
  clientUploadFile,
  postClientDownloadFile
} from './clients'

const request = axiosClient({
  timeout: 60 * 2 * 1000 // Waiting 2 min
})

export async function statementMappingCreateRequest(
  file: File,
  currency: string,
  company: string
) {
  return clientUploadFile('/api/statements/create/', file, {
    currency,
    company_uuid: company
  })
}

export async function statementMappingUpdateRequest(
  uuid: string,
  status: string,
  date?: string
) {
  return request.post('/api/statements/update/', {
    uuid,
    date,
    status
  })
}

export async function statementMappingReportRequest(uuid: string) {
  return postClientDownloadFile('/api/statements/report/create/', { uuid })
}

export async function statementMappingGetRequest(uuid: string) {
  return request.get(`/api/statements/${uuid}/`)
}

export async function statementMappingListRequest(
  offset: number,
  limit: number = 10,
  rut: string = '',
  ownerBalances: boolean = false
) {
  return request.get('/api/statements/', {
    params: { offset, limit, rut, owner: ownerBalances }
  })
}

export async function statementAccountClassesListRequest() {
  return request.get('/api/statements/account-classes/')
}

export async function statementItemListRequest(uuid: string) {
  return request.get(`/api/statements/${uuid}/items/`)
}

/**
 * Sends a POST request to update a list of statement items.
 *
 * @param uuid - The unique identifier of the statement.
 * @param data - An array of objects, each representing a statement item to be updated.
 *  Each object should have keys and values representing the fields to be updated.
 * @returns A Promise that resolves with the response of the HTTP request.
 */
export function statementItemsUpdateRequest(
  uuid: string,
  data: Partial<CuentaType>[]
) {
  return request.post(`/api/statements/${uuid}/items/update/`, data)
}

/**
 * Sends a POST request to create a statement item
 * @param uuid - The unique identifier of the statement.
 * @param data - An object representing the statement item to be created.
 * @returns  A Promise that resolves with the response of the HTTP request.
 */
export function statementItemsCreateRequest(
  uuid: string,
  data: Partial<requestCuentaType>
) {
  return request.post(`/api/statements/${uuid}/items/create/`, data)
}
