import workingGifBci from '@/assets/monobci.gif'
import { Box, LinearProgress, Modal, Typography } from '@mui/material'
import React from 'react'

interface ProcessingModalProps {
  open: boolean
}

const ProcessingModal: React.FC<ProcessingModalProps> = ({ open }) => {
  return (
    <Modal open={open}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70vh"
          width="70vh"
          flexDirection="column"
          bgcolor="background.paper"
          p={4}
          borderRadius={2}
          boxShadow={24}
        >
          <LinearProgress />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="40vh"
            flexDirection="column"
          >
            <img src={workingGifBci} width={130} alt="Working" />
            <Typography variant="h6">Se está procesando el archivo.</Typography>
            <Typography>Por favor espere y no cierre esta ventana.</Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default ProcessingModal
