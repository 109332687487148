import { ReactElement } from 'react'
import { Outlet } from 'react-router-dom'

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import BuildCircleIcon from '@mui/icons-material/BuildCircle'
import ListIcon from '@mui/icons-material/List'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import UploadFileIcon from '@mui/icons-material/UploadFile'

import Balance from '@/pages/Balance'
import BalanceDetails from '@/pages/BalanceDetails'
import BalanceDetailsClassified from '@/pages/BalanceDetailsClassified'
import BalanceUpload from '@/pages/BalanceUpload'
import Companies from '@/pages/Companies'
import ReportUserStats from '@/pages/ReportUserStats'
import { Group } from '@/utils'

export interface Route {
  path: string
  name: string
  icon?: ReactElement
  element: ReactElement
  loader?: () => Promise<{ default: ReactElement }>
  children?: Route[]
  hiddenMenu?: boolean
  groups?: string[]
}

const privateRoutes: Route[] = [
  {
    path: '/statements',
    name: 'Balances',
    icon: <AccountBalanceWalletIcon />,
    element: <Balance />
  },
  {
    path: '/statements/:uuid',
    name: 'Detalle',
    icon: <AccountBalanceWalletIcon />,
    element: <BalanceDetails />,
    hiddenMenu: true
  },
  {
    path: '/statements/:uuid/classified',
    name: 'Detalle Clasificado',
    icon: <AccountBalanceWalletIcon />,
    element: <BalanceDetailsClassified />,
    hiddenMenu: true
  },
  {
    path: '/statements/upload',
    name: 'Subir Balances',
    icon: <UploadFileIcon />,
    element: <BalanceUpload />,
    hiddenMenu: true
  },
  {
    path: '/companies',
    name: 'Empresas',
    icon: <ShoppingBasketIcon />,
    element: <Companies />
  },
  {
    path: '/config',
    name: 'Configuración',
    icon: <BuildCircleIcon />,
    element: <Outlet />,
    groups: [Group.Jefatura],
    children: [
      {
        path: '/config/report-user-stats',
        name: 'Reporte de Usuarios',
        icon: <ListIcon />,
        element: <ReportUserStats />,
        groups: [Group.Jefatura]
      }
    ]
  }
]

export default privateRoutes
