import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

interface SelectDateProps {
  date: string
  setDate: (date: string) => void
  label: string
  showToolbar?: boolean
  disableDates?: boolean
  fetchNonBusinessDays?: () => void
  nonBusinessDaysCache?: string[]
}

const SelectDate: React.FC<SelectDateProps> = ({
  date,
  setDate,
  label,
  disableDates = false,
  nonBusinessDaysCache = []
}) => {
  // if date is not set then don't return
  if (!!date !== true) {
    return <div>Cargando fecha</div>
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        format="DD/MM/YYYY"
        value={moment(date)}
        onChange={(e) => setDate(moment(e).format('YYYY-MM-DD'))}
        disableFuture
        shouldDisableDate={(day) =>
          disableDates
            ? false
            : nonBusinessDaysCache?.includes(day.format('YYYY-MM-DD'))
        }
        openTo="day"
        views={['year', 'month', 'day']}
        slotProps={{ textField: { size: 'small' } }}
      />
    </LocalizationProvider>
  )
}

export default SelectDate
