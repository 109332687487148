import { FC } from 'react'

import TextField from '@mui/material/TextField'

interface CustomTextFieldProps {
  name: string
  label: string
}

const NumericField: FC<CustomTextFieldProps> = ({ name, label }) => (
  <TextField
    name={name}
    label={label}
    type="number"
    defaultValue={0}
    fullWidth
    variant="outlined"
    size="small"
    onFocus={(e) => {
      if (e.target.value === '0') {
        e.target.value = ''
      }
    }}
    onBlur={(e) => {
      if (e.target.value === '') {
        e.target.value = '0'
      }
    }}
  />
)

export default NumericField
