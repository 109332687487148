import { Link } from 'react-router-dom'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import CenteredBox from '@/components/CenteredBox'

import monoPng from '@/assets/monoamarillo.png'

const NotFoundPage = () => {
  return (
    <div>
      <CenteredBox>
        <Typography component="h1" variant="h5">
          Error 404: Página no encontrada
        </Typography>
        <hr className="my-2" />
        <p className="lead">
          <img
            src={monoPng}
            alt="Logo BCI Asset Management"
            style={{ height: 255, width: 180 }}
          />
        </p>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              margin: (theme) => theme.spacing(1)
            }}
          >
            Ir al inicio
          </Button>
        </Link>
      </CenteredBox>
    </div>
  )
}

export default NotFoundPage
