import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PlayDisabledIcon from '@mui/icons-material/PlayDisabled'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

import useDebounce from '@/hooks/useDebounce'
import { statementMappingListRequest } from '@/requests/accounts'

type DataType = {
  uuid: string
  date: string
  username: string
  created: string
  modified: string
  file_name: string
  status: string
  get_status_display: string
  company_name: string
  company_rut: string
  get_statement_type_display: string
}

const Balance: React.FC = () => {
  const [data, setData] = useState<DataType[]>([])
  const [count, setCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [offset, setOffset] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [rut, setRut] = useState('')
  const [ownerBalances, setOwnerBalances] = useState(false)
  const debouncedRut = useDebounce(rut, 850)
  const navigate = useNavigate()

  const handleChangePage = (_event: unknown, newPage: number) => {
    setData([])
    const newOffset = newPage * rowsPerPage
    setSearchParams({
      offset: newOffset.toString(),
      limit: rowsPerPage.toString()
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setData([])
    const newRowsPerPage = parseInt(event.target.value, 10)
    setSearchParams({ offset: '0', limit: newRowsPerPage.toString() })
  }

  const handleBalanceTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newBalanceType: boolean | null
  ) => {
    if (newBalanceType !== null) {
      setData([])
      setSearchParams({ offset: '0', limit: '10' })
      setOwnerBalances(newBalanceType)
    }
  }

  useEffect(() => {
    const offsetFromUrl = parseInt(searchParams.get('offset') || '0')
    const limitFromUrl = parseInt(searchParams.get('limit') || '10')
    setOffset(offsetFromUrl)
    setRowsPerPage(limitFromUrl)
    setData([])
  }, [searchParams])

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedRut !== rut) {
        return
      }
      try {
        const offsetFromUrl = parseInt(searchParams.get('offset') || '0')
        const limitFromUrl = parseInt(searchParams.get('limit') || '10')
        const response = await statementMappingListRequest(
          offsetFromUrl,
          limitFromUrl,
          debouncedRut,
          ownerBalances
        )
        setData(response.data.results)
        setCount(response.data.count)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [searchParams, debouncedRut, rut, ownerBalances])

  return (
    <Box>
      <Box
        display="flex"
        mb={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Balances
        </Typography>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <TextField
              label="Filtrar por RUT"
              value={rut}
              size="small"
              InputProps={{
                endAdornment: <SearchIcon sx={{ color: 'grey' }} />
              }}
              onChange={(e) => {
                setRut(e.target.value)
                setSearchParams({ offset: '0', limit: rowsPerPage.toString() })
              }}
            />
          </Box>
          <ToggleButtonGroup
            color="primary"
            value={ownerBalances}
            exclusive
            onChange={handleBalanceTypeChange}
            sx={{ mr: 2 }}
          >
            <ToggleButton value={false}>Todos</ToggleButton>
            <ToggleButton value={true}>Mis balances</ToggleButton>
          </ToggleButtonGroup>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/statements/upload')}
          >
            Subir Balance
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white',
                  whiteSpace: 'nowrap'
                }}
              >
                RUT
              </TableCell>
              <TableCell
                sx={{ backgroundColor: 'primary.main', color: 'white' }}
              >
                Empresa
              </TableCell>
              <TableCell
                sx={{ backgroundColor: 'primary.main', color: 'white' }}
              >
                Periodo
              </TableCell>
              <TableCell
                sx={{ backgroundColor: 'primary.main', color: 'white' }}
              >
                Estado
              </TableCell>
              <TableCell
                sx={{ backgroundColor: 'primary.main', color: 'white' }}
              >
                Usuario
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white'
                }}
              >
                Tipo
              </TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow
                key={`${item.uuid}-${index}`}
                sx={{
                  '&:hover': {
                    backgroundColor: '#0000000A'
                  },
                  cursor: 'pointer'
                }}
                onClick={() => {
                  if (
                    item.get_statement_type_display !== 'IFRS' &&
                    item.get_statement_type_display !== 'Clasificado'
                  ) {
                    navigate(`/statements/${item.uuid}`)
                  }
                }}
                data-uuid={item.uuid}
              >
                <TableCell
                  sx={{ whiteSpace: 'pre-line', fontFeatureSettings: "'tnum'" }}
                >
                  {item.company_name
                    ? item.company_rut
                    : 'No hay empresa asociada'}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'pre-line' }}>
                  {item.company_name
                    ? item.company_name
                    : 'No hay empresa asociada'}
                </TableCell>
                <TableCell sx={{ minWidth: 110 }}>
                  {item.date ? dayjs(item.date).format('YYYY/MM') : ''}
                </TableCell>
                <TableCell>
                  <Chip
                    label={item.get_status_display}
                    style={{
                      backgroundColor:
                        item.status === 'EX'
                          ? '#9c27b0' // Purple for "Extrayendo"
                          : item.status === 'PR'
                          ? '#3f51b5' // Indigo for "Procesando"
                          : item.status === 'CL'
                          ? '#009688' // Teal for "Clasificando"
                          : item.status === 'PA'
                          ? '#FFE7BA' // Orange for "Aprobación pendiente"
                          : item.status === 'AP'
                          ? '#D9F7BE' // Green for "Aprobado"
                          : '#e0e0e0', // Grey for any other status
                      color:
                        item.status === 'EX'
                          ? '#000000' // Purple for "Extrayendo"
                          : item.status === 'PR'
                          ? '#000000' // Indigo for "Procesando"
                          : item.status === 'CL'
                          ? '#000000' // Teal for "Clasificando"
                          : item.status === 'PA'
                          ? '#873800' // Orange for "Aprobación pendiente"
                          : item.status === 'AP'
                          ? '#135200' // Green for "Aprobado"
                          : '#e0e0e0' // Grey for any other status
                    }}
                  />
                </TableCell>
                <TableCell>{item.username}</TableCell>
                <TableCell>{item.get_statement_type_display}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Ir al balance" placement="left">
                    {item.get_statement_type_display === 'Clasificado' ? (
                      <Link
                        to={`/statements/${item.uuid}/classified`}
                        style={{ color: 'inherit', textDecoration: 'none' }}
                      >
                        <ArrowForwardIcon />
                      </Link>
                    ) : item.get_statement_type_display !== 'IFRS' ? (
                      <Link
                        to={`/statements/${item.uuid}`}
                        style={{ color: 'inherit', textDecoration: 'none' }}
                      >
                        <ArrowForwardIcon />
                      </Link>
                    ) : (
                      <PlayDisabledIcon style={{ color: 'grey' }} />
                    )}
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={offset / rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Filas por página:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </Box>
  )
}

export default Balance
