import axios from 'axios'
import { useState } from 'react'
import { FileWithPath } from 'react-dropzone'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import CheckIcon from '@mui/icons-material/Check'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'

import CompanyAutocomplete from '@/components/CompanyAutocomplete'
import DropzoneUploadFile from '@/components/DropzoneUploadFile'
import ProcessingModal from '@/components/ProcessingModal'
import { statementMappingCreateRequest } from '@/requests/accounts'

const AccountTributarioClassification = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [company, setCompanyUUID] = useState('')
  const [currency, setCurrency] = useState('')
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPath[]>([])
  const [submitting, setSubmitting] = useState(false)

  const clearAcceptedFiles = () => {
    setAcceptedFiles([])
  }

  async function handleSubmit() {
    try {
      await handleFileUpload(acceptedFiles)
      clearAcceptedFiles()
    } catch (error) {
      console.error('Failed to upload file:', error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleFileUpload = async (file: File[]) => {
    if (!company || !currency) {
      toast.error('Por favor, selecciona la empresa y la moneda', {
        toastId: 'error-token',
        position: toast.POSITION.BOTTOM_RIGHT
      })
      throw new Error('Por favor, selecciona la empresa y la moneda')
    }
    try {
      const response = await statementMappingCreateRequest(
        file[0],
        currency,
        company
      )
      const statementUUID = response?.data?.uuid
      navigate(`/statements/${statementUUID}`)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Display a toast notification with the error message
        toast.error(`Error: ${error.message}`, {
          toastId: 'error-token',
          position: toast.POSITION.BOTTOM_RIGHT
        })
        throw new Error(error.message)
      }
    }
  }

  const handleCompanySelect = ({
    companyUUID
  }: {
    companyUUID: string
    companyRUT?: string
    companyName?: string
  }) => {
    if (!companyUUID) {
      return
    }
    setCompanyUUID(companyUUID)
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Paper
        elevation={3}
        variant="elevation"
        style={{ padding: '16px', marginBottom: '16px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Información</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CompanyAutocomplete onCompanySelect={handleCompanySelect} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">Seleccionar moneda</MenuItem>
                  <MenuItem value="CLP">Pesos Chilenos</MenuItem>
                  <MenuItem value="MCLP">Miles de Pesos Chilenos</MenuItem>
                  <MenuItem value="USD">Dólares Estadounidenses</MenuItem>
                  {/* Add more currencies as needed and add them in the backend too */}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={3} variant="elevation" style={{ padding: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="h6">Subir archivo</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <DropzoneUploadFile
              setAcceptedFiles={setAcceptedFiles}
              acceptedFiles={acceptedFiles}
              acceptedFileTypes={{
                'application/pdf': ['.pdf'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  []
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box display="flex" justifyContent="flex-end">
        <Button
          sx={{
            margin: theme.spacing(1, 1, 1, 0),
            color: theme.palette.grey[700],
            borderColor: theme.palette.grey[700]
          }}
          type="button"
          variant="outlined"
          onClick={() => {
            clearAcceptedFiles()
            navigate('/statements')
          }}
        >
          Cancelar
        </Button>
        <Button
          sx={{
            margin: theme.spacing(1, 1, 1, 0)
          }}
          onClick={() => {
            setSubmitting(true)
            handleSubmit()
          }}
          variant="contained"
          color="primary"
          disabled={acceptedFiles.length === 0}
          startIcon={<CheckIcon />}
        >
          Crear reporte
        </Button>
      </Box>
      <ProcessingModal open={submitting} />
    </Box>
  )
}

export default AccountTributarioClassification
