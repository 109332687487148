import { ReactNode } from 'react'

import { Box } from '@mui/system'

import backgroundImage from '@/assets/fondo_colors.png'

interface CenteredBoxProps {
  children: ReactNode
}

const CenteredBox = ({ children }: CenteredBoxProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100vh"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      {children}
    </Box>
  )
}

export default CenteredBox
